/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink, EuiSpacer, EuiTitle } from '@elastic/eui'

import type { DeploymentGetResponse, DeploymentsListingData } from '@modules/cloud-api/v1/types'
import type { AnyUserProject } from '@modules/project-user-api/types'

import { useProfile } from '../../profile-lib/hooks'
import { useKibanaLinks } from '../../kibana-redirect/hooks/useKibanaLinks'
import { useOnboardingTokenFromProfile } from '../../discovery-questions-lib/hooks'

type Props = {
  deployment: DeploymentsListingData | undefined
  project: AnyUserProject | undefined
}

const Instances: React.FunctionComponent<Props> = ({ deployment, project }) => {
  const profile = useProfile()
  const tmpOnboardingToken = useOnboardingTokenFromProfile()
  const { deploymentLink, projectLink } = useKibanaLinks({
    profile: profile ? { user: profile } : undefined,
    deployment: deployment as unknown as DeploymentGetResponse,
    onboardingToken: tmpOnboardingToken,
    project,
  })

  if (!deployment && !project) {
    return null
  }

  return (
    <Fragment>
      <EuiTitle size='xs'>
        <h2>
          <FormattedMessage
            id='trialSummary.instanceTitle'
            defaultMessage='Your {numberOfInstances, plural, one {instance} other {instances}}'
            values={{
              numberOfInstances: [deployment, project].filter(Boolean).length,
            }}
          />
        </h2>
      </EuiTitle>
      <EuiSpacer size='xs' />
      {deployment && (
        <div>
          <EuiSpacer size='xs' />
          <EuiLink href={deploymentLink} external={true} target='_blank'>
            {deployment.name}
          </EuiLink>
        </div>
      )}
      {project && (
        <div>
          <EuiSpacer size='xs' />
          <EuiLink href={projectLink} external={true} target='_blank'>
            {project.name}
          </EuiLink>
        </div>
      )}
    </Fragment>
  )
}

export default Instances
