/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useContext } from 'react'

import AuthContext from './context'

import type { AuthContextData } from './context'

interface AuthContextProps {
  authContext: AuthContextData
}

const withAuthContext = <T extends AuthContextProps>(WrappedComponent: React.ComponentType<T>) => {
  const ComponentWithAuthContext = (props: Omit<T, keyof AuthContextProps>) => {
    const { authContext } = useContext(AuthContext)

    return <WrappedComponent {...(props as T)} authContext={authContext} />
  }

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  ComponentWithAuthContext.displayName = `withAuthContext(${displayName})`

  return ComponentWithAuthContext
}

export default withAuthContext
