/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { Fragment, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSkeletonRectangle,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type { CSP, Region } from '@modules/cluster-user-api/v1/types'
import ProviderSelector from '@modules/project-creation-components/ProviderSelector'
import { useListRegionsQuery } from '@modules/cluster-user-lib/hooks'
import { StepLayout } from '@modules/cui/Step/StepLayout'
import { useBoundProviderAndRegionState } from '@modules/project-creation-lib/hooks'
import type { NonEmptyArray } from '@modules/ts-utils'

import messages from './messages'
import ServerlessRegionSelector from './ServerlessRegionSelector'

import type { FunctionComponent } from 'react'

export interface ChooseCSPRegionProps {
  isDisabled: boolean
  onRegionConfirmed: (region: Region) => void
  isContinueStep?: boolean
}

const ChooseCSPRegion: FunctionComponent<ChooseCSPRegionProps> = ({
  children,
  isDisabled,
  isContinueStep = false,
  onRegionConfirmed,
}) => {
  const { data: regions, isError, isLoading, refetch } = useListRegionsQuery()

  const [region, setRegion] = useState<Region | undefined>(undefined)

  let buttonMsg = <FormattedMessage {...messages.launch} />

  if (isDisabled) {
    buttonMsg = <FormattedMessage {...messages.loading} />
  } else if (isContinueStep) {
    buttonMsg = <FormattedMessage {...messages.continue} />
  }

  return (
    <Fragment>
      <StepLayout
        title={<FormattedMessage {...messages.title} />}
        description={<FormattedMessage {...messages.subtitle} />}
        footer={
          !isError && (
            <div>
              <EuiButton
                data-test-id='signup.discovery-questions.launch'
                isLoading={isDisabled || !region}
                fill={true}
                onClick={() => {
                  if (region === undefined) {
                    // won't be undefined, since the button won't be enabled if region is undefined
                    return
                  }

                  onRegionConfirmed(region)
                }}
              >
                {buttonMsg}
              </EuiButton>
            </div>
          )
        }
      >
        <ChooseCSPRegionInnerContent
          isError={isError}
          isLoading={isLoading}
          refetch={refetch}
          regions={regions}
          onChange={setRegion}
        />
      </StepLayout>
      {!isLoading && children && <Fragment>{children}</Fragment>}
    </Fragment>
  )
}

type ChooseCSPRegionInnerContentProps = {
  isError: boolean
  isLoading: boolean
  regions: Region[] | undefined
  refetch: () => void
  onChange: (region: Region) => void
}

export const ChooseCSPRegionInnerContent: FunctionComponent<ChooseCSPRegionInnerContentProps> = ({
  isError,
  isLoading,
  regions,
  refetch,
  onChange,
}) => {
  const { formatMessage } = useIntl()

  if (isError) {
    return <FetchErrorCallOut refetch={refetch} />
  }

  if (isLoading || regions === undefined) {
    return (
      <EuiFlexGroup>
        <EuiSkeletonRectangle width='196px' height='38px' />
        <EuiSkeletonRectangle width='88' height='38px' />
      </EuiFlexGroup>
    )
  }

  if (regions.length === 0) {
    return (
      <EuiCallOut title={formatMessage(messages.error)} color='danger' iconType='alert'>
        {formatMessage(messages.noRegions)}
      </EuiCallOut>
    )
  }

  return <CSPRegionFormRow regions={regions as NonEmptyArray<Region>} onChange={onChange} />
}

const FetchErrorCallOut: FunctionComponent<{ refetch: () => void }> = ({ refetch }) => {
  const { formatMessage } = useIntl()

  return (
    <EuiCallOut
      title={formatMessage(messages.error)}
      color='danger'
      iconType='alert'
      data-test-id='signup.discovery-questions.error'
    >
      <EuiButton
        onClick={() => refetch()}
        color='danger'
        aria-label={formatMessage({
          ...messages.retry,
        })}
      >
        {formatMessage(messages.retry)}
      </EuiButton>
    </EuiCallOut>
  )
}

const CSPRegionFormRow: FunctionComponent<{
  regions: NonEmptyArray<Region>
  onChange: (region: Region) => void
}> = ({ regions, onChange }) => {
  const cloudProviderHtmlId = useGeneratedHtmlId()

  const regionHtmlId = useGeneratedHtmlId()

  const {
    selectedRegion,
    selectedProvider,
    providers,
    providerRegions,
    setSelectedProvider,
    setSelectedRegion,
  } = useBoundProviderAndRegionState(regions)

  useEffect(() => {
    onChange(selectedRegion)
  }, [selectedRegion, onChange])

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiFormRow
          label={
            <EuiTitle size='xxxs'>
              <EuiText id={cloudProviderHtmlId}>
                <FormattedMessage {...messages.cloudProvider} />
              </EuiText>
            </EuiTitle>
          }
        >
          <ProviderSelector
            value={selectedProvider}
            technicalPreview={true}
            availableProviders={providers}
            comingSoon={true}
            onChange={(provider: CSP) => setSelectedProvider(provider)}
            showPrepend={false}
            aria-labelledby={cloudProviderHtmlId}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFormRow
          label={
            <EuiTitle size='xxxs'>
              <EuiText id={regionHtmlId}>
                <FormattedMessage {...messages.region} />
              </EuiText>
            </EuiTitle>
          }
        >
          <ServerlessRegionSelector
            selectedRegion={selectedRegion}
            allowedRegions={providerRegions}
            comingSoon={true}
            onSelectionChange={setSelectedRegion}
            aria-labelledby={regionHtmlId}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default ChooseCSPRegion
