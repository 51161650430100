/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'

import { supportPortalUrl } from '@/apps/userconsole/urls'

const InactiveOverviewPanel = () => (
  <EuiPanel
    hasShadow={false}
    paddingSize='l'
    hasBorder={true}
    borderRadius='m'
    data-test-id='inactive-plan-card'
  >
    <EuiFlexGroup direction='column' justifyContent='spaceBetween' css={css({ height: '100%' })}>
      <EuiFlexItem grow={10}>
        <EuiText size='m'>
          <h6>
            <FormattedMessage id='mp-billing-overview.plan' defaultMessage='Plan' />
          </h6>
        </EuiText>

        <EuiSpacer size='m' />

        <EuiText size='m' data-test-id='inactive-plan-card.title'>
          <h3>
            <FormattedMessage id='mp-billing-overview.inactive-title' defaultMessage='Inactive' />
          </h3>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <div>
          <EuiButton element='button' fill={true} href={supportPortalUrl} target='_blank'>
            <FormattedMessage
              id='mp-billing-overview.inactive.contact-button'
              defaultMessage='Contact support'
            />
          </EuiButton>
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiPanel>
)

export default InactiveOverviewPanel
