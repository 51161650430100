/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { enhanceUserProfileFromApi } from '@modules/profile-lib'
import type { ProfileState, UserProfileFromApi, UserSubscription } from '@modules/ui-types'

import { FETCH_DRIFT_JWT, FETCH_PROFILE } from '@/apps/userconsole/constants/actions'

import type { ReduxState } from '@/types/redux'
import type { FetchDriftJwtAction, FetchProfileAction } from './profileTypes'

export type State = ProfileState

export default function profileReducer(
  state: State = null,
  action: FetchProfileAction | FetchDriftJwtAction,
): State {
  if (action.type === FETCH_PROFILE) {
    if (!action.error && action.payload) {
      const user = {
        ...action.payload.user,
        ...action.payload.rules,
        ...action.payload.subscription,
        trials: action.payload.trials,
      } as UserProfileFromApi
      return enhanceUserProfileFromApi(user, state)
    }
  }

  if (action.type === FETCH_DRIFT_JWT) {
    if (!action.error && action.payload) {
      const { token } = action.payload

      return {
        ...state!,
        driftJwt: token,
      }
    }
  }

  return state
}

export function getProfile(state: ReduxState): State {
  const profile = state.profile

  if (!profile && window != null && window.location.pathname.toLowerCase().startsWith('/pricing')) {
    return getLoggedOutPublicPricingPageProfile()
  }

  return profile
}

export function checkIsProfileLoggedOutPublicPricingPage(profile: ProfileState): boolean {
  const loggedOutPublicPricingPageProfile = getLoggedOutPublicPricingPageProfile()

  return !profile || profile.user_id === loggedOutPublicPricingPageProfile?.user_id
}

export function getExternalSubscription({ profile }): UserSubscription {
  if (!profile) {
    return null
  }

  if (profile.domain === `found`) {
    return null
  }

  return profile.domain
}

function getLoggedOutPublicPricingPageProfile(): State {
  return {
    allow_bundles: false,
    allow_plugins: false,
    allow_provisioning_without_payment_established: false,
    aws_subscribed: false,
    azure_subscribed: false,
    capacity_limit: -1,
    contract_type: `monthly`,
    created: ``,
    credit_limit: -1,
    data: {},
    domain: `found`,
    email: ``,
    mfa_enforced: false,
    gcp_subscribed: false,
    gravatar: ``,
    invoicable: false,
    is_paying: true,
    is_profile_editable: false,
    is_trial: false,
    last_modified: ``,
    level: `standard`,
    pending_marketplace_subscription: false,
    trials: [],
    txid: -1,
    user_id: -1,
    wants_informational_emails: false,
    integrated_marketplace_account: false,

    // Calculated properties
    canRestartTrial: false,
    canUploadPlugins: false,
    hasExpiredTrial: false,
    inTrial: false,
    isPremium: false,
  }
}
