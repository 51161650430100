/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import ReactDOM from 'react-dom'

import DriftChatIframe from './DriftChatIframe'

import { wrapDriftChat } from '.'

import type { ReactNode } from 'react'
import type { AllProps } from './types'

export type DriftChatWrapee = (props: { triggerChat: () => void }) => ReactNode | null

export type Props = AllProps

function BottomDriftChat({ profile, onOpen, ...props }: Props) {
  if (!profile) {
    return null
  }

  return ReactDOM.createPortal(
    <DriftChatIframe {...props} profile={profile} onOpen={onOpen} />,
    document.body,
  )
}

export default wrapDriftChat(BottomDriftChat)
