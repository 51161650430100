/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { useHistory } from 'react-router'

import {
  EuiFlexItem,
  EuiSpacer,
  EuiFlexGrid,
  EuiText,
  EuiSkeletonText,
  useEuiTheme,
  EuiShowFor,
  EuiLink,
} from '@elastic/eui'
import type { UseEuiTheme } from '@elastic/eui'

import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import {
  isDrawdown,
  isMonthly,
  isPrepaidConsumptionCustomer,
  isInactive,
  isTrial,
  getMarketplaceDisplayName,
  getMarketplaceBillingLink,
} from '@modules/billing-lib/billingDetails/utils'
import { useConfig } from '@modules/cui/ConfigContext'
import { useBillingHistoryUrl } from '@modules/urls/billing'

import OrganizationPrepaids from '@/components/Organization/OrganizationPrepaids'
import SubscriptionDetailsCard from '@/components/User/BillingOverviewV2/components/SubscriptionDetailsCard'
import SuggestedContentPanel from '@/apps/userconsole/components/Billing/BillingOverview/SuggestedContentPanel'
import ExternalLink from '@/components/ExternalLink'

import UsageCardV2 from '../components/UsageCardV2'
import PrepaidConsumptionOverviewCard from '../components/PrepaidConsumptionOverviewCard'
import PrepaidAccountDetailsPanel from '../../BillingOverview/PrepaidAccountDetailsPanel'
import PaymentDetailsCard from '../../BillingSubscription/components/PaymentDetailsCard'

import MonthlyOrDrawdownOverviewPanel from './MonthlyOrDrawdownOverviewPanel'
import InactiveOverviewPanel from './InactiveOverviewPanel'
import TrialOverview from './TrialOverview'

import type { ReactElement } from 'react'

interface MarketplacesOverviewProps {
  organizationId: string
}

const gridContainerCss = (theme: UseEuiTheme) =>
  css({
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridTemplateRows: 'auto auto',

    [`@media screen and (max-width: ${theme.euiTheme.breakpoint.l}px)`]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gridTemplateRows: 'auto auto',
    },

    [`@media screen and (max-width: ${theme.euiTheme.breakpoint.m}px)`]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
  })

const gridItemCss = (theme: UseEuiTheme) =>
  css({
    gridColumn: 'span 2',

    [`@media screen and (max-width: ${theme.euiTheme.breakpoint.m}px)`]: {
      gridColumn: 'span 1',
    },
  })

function MarketplacesOverview({ organizationId }: MarketplacesOverviewProps): ReactElement {
  const theme = useEuiTheme()
  const billingDetails = useBillingDetails()
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'
  const history = useHistory()
  const billingHistoryUrl = useBillingHistoryUrl(organizationId)

  const renderOverviewPanel = (): JSX.Element | null => {
    // sanity
    if (!billingDetails.data) {
      return null
    }

    if (isPrepaidConsumptionCustomer(billingDetails.data)) {
      return <PrepaidConsumptionOverviewCard organizationId={organizationId} />
    }

    if (isMonthly(billingDetails.data) || isDrawdown(billingDetails.data)) {
      return <MonthlyOrDrawdownOverviewPanel organizationId={organizationId} />
    }

    if (isInactive(billingDetails.data)) {
      return <InactiveOverviewPanel />
    }

    return null
  }

  const renderPaymentDetailsCard = ({ grow }: { grow: boolean }) => (
    <PaymentDetailsCard
      grow={grow}
      details={
        <Fragment>
          <EuiText size='s'>
            <FormattedMessage
              id='marketplace-overview.payment-details.details'
              defaultMessage={`Billed through your {domain} account.`}
              values={{ domain: getMarketplaceDisplayName(billingDetails.data!.channel) }}
            />
          </EuiText>
          <EuiSpacer size='s' />
          <EuiLink onClick={() => history.push(billingHistoryUrl)}>
            <FormattedMessage
              id='marketplace-overview.payment-details.view-billing-history'
              defaultMessage='View billing history'
            />
          </EuiLink>
        </Fragment>
      }
      footer={
        <EuiText size='s'>
          <FormattedMessage
            data-test-id='marketplace-account-info-text'
            id='marketplace-overview.payment-details.footer'
            defaultMessage='To make adjustments to your payment or subscription, go to your {marketplaceAccount}.'
            values={{
              marketplaceAccount: (
                <ExternalLink
                  href={getMarketplaceBillingLink(billingDetails.data!.channel)}
                  data-test-id='marketplace-account-link'
                >
                  <FormattedMessage
                    id='billing-details-summary.next-bill.marketplace-account-link'
                    defaultMessage='Marketplace account'
                  />
                </ExternalLink>
              ),
            }}
          />
        </EuiText>
      }
    />
  )

  if (!billingDetails.data) {
    return <EuiSkeletonText />
  }

  if (isTrial(billingDetails.data)) {
    return <TrialOverview organizationId={organizationId} />
  }

  return (
    <EuiFlexGrid gutterSize='m' css={gridContainerCss(theme)}>
      <EuiShowFor sizes={['xs', 's', 'l', 'xl']}>
        <EuiFlexItem>
          <UsageCardV2 organizationId={organizationId} simplifyView={true} />
        </EuiFlexItem>

        <EuiFlexItem>{renderOverviewPanel()}</EuiFlexItem>

        <EuiFlexItem>
          <SubscriptionDetailsCard />
        </EuiFlexItem>

        <EuiFlexItem>
          {renderPaymentDetailsCard({ grow: false })}
          <EuiSpacer size='m' />
          <SuggestedContentPanel
            hasBorder={true}
            data-test-id='billing-details-summary.suggested-content'
            showEcuMessage={true}
          />
        </EuiFlexItem>

        <EuiFlexItem css={gridItemCss(theme)}>
          <PrepaidAccountDetailsPanel hasBorder={true} organizationId={organizationId} />
          <EuiSpacer size='m' />
          {isAdminConsole && <OrganizationPrepaids organizationId={organizationId} />}
        </EuiFlexItem>
      </EuiShowFor>
      {/*Tablet view has a different layout */}
      <EuiShowFor sizes={['m']}>
        <EuiFlexItem>
          <UsageCardV2 organizationId={organizationId} simplifyView={true} />
        </EuiFlexItem>

        <EuiFlexItem>{renderOverviewPanel()}</EuiFlexItem>
        <EuiFlexItem> {renderPaymentDetailsCard({ grow: true })}</EuiFlexItem>
        <EuiFlexItem>
          <SubscriptionDetailsCard />
        </EuiFlexItem>
        <EuiFlexItem css={gridItemCss(theme)}>
          <PrepaidAccountDetailsPanel hasBorder={true} organizationId={organizationId} />
          {isAdminConsole && (
            <Fragment>
              <EuiSpacer size='m' />
              <OrganizationPrepaids organizationId={organizationId} />
            </Fragment>
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          <SuggestedContentPanel
            hasBorder={true}
            data-test-id='billing-details-summary.suggested-content'
            showEcuMessage={true}
          />
        </EuiFlexItem>
      </EuiShowFor>
    </EuiFlexGrid>
  )
}

export default MarketplacesOverview
