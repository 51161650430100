/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { useProfile } from '../../profile-lib/hooks'
import {
  useOnboardingToken,
  usePutDiscoveryQuestionsMutation,
  type DiscoveryQuestionsStateType,
} from '../../discovery-questions-lib/hooks'
import {
  EXPERIENCE_LEVELS_VALUES,
  TRIAL_INTENTS_VALUES,
  USE_CASES_VALUES,
} from '../../discovery-questions-lib/utils'
import { postCloudAnalytics } from '../../analytics-api/caller'

import type { OnboardingToken } from '../../discovery-questions-lib/steps'

interface UsePersistOnboardingQuestions {
  putDiscoveryQuestions: (onSuccess?: () => void) => Promise<void>
}

interface UsePersistOnboardingQuestionsProps {
  answers: Partial<DiscoveryQuestionsStateType>
  setErrors: React.Dispatch<React.SetStateAction<React.ReactNode[]>>
  setResourceCreationLaunched: (resourceCreationLaunched: boolean) => void
}

export const usePersistOnboardingQuestions = ({
  answers,
  setErrors,
  setResourceCreationLaunched,
}: UsePersistOnboardingQuestionsProps): UsePersistOnboardingQuestions => {
  const { user_id: userId } = useProfile() ?? { user_id: undefined }
  const onboardingToken = useOnboardingToken()
  const { mutateAsync } = usePutDiscoveryQuestionsMutation()

  const putDiscoveryQuestions = useCallback(
    async (onSuccess?: () => void) => {
      await mutateAsync(
        { body: discoveryQuestionsStateTypeToPayload(answers, onboardingToken) },
        {
          onError: () => {
            setErrors([
              <FormattedMessage
                id='onboarding-page.error-message'
                defaultMessage='Sorry, we were unable to save your answers. Ensure all questions in the previous steps were answered.'
              />,
            ])

            setResourceCreationLaunched(false)
          },
          onSuccess: () => {
            postCloudAnalytics({
              userId,
              questions: discoveryQuestionsStateTypeToPayload(answers, onboardingToken),
            })
            onSuccess?.()
          },
        },
      )
    },
    [answers, mutateAsync, onboardingToken, setErrors, setResourceCreationLaunched, userId],
  )

  return {
    putDiscoveryQuestions,
  }
}

const discoveryQuestionsStateTypeToPayload = (
  state: Partial<DiscoveryQuestionsStateType>,
  onboardingToken?: OnboardingToken,
) => {
  const { experience_level, trial_intent, use_case, full_name, company, use_case_detail } = state

  return {
    experience_level: experience_level
      ? EXPERIENCE_LEVELS_VALUES[experience_level]
      : EXPERIENCE_LEVELS_VALUES.new,
    trial_intent: trial_intent ? TRIAL_INTENTS_VALUES[trial_intent] : TRIAL_INTENTS_VALUES.learn,
    use_case: use_case ? USE_CASES_VALUES[use_case] : USE_CASES_VALUES.search,
    full_name: full_name ? full_name : '',
    company: company ? company : '',
    onboarding_token: onboardingToken,
    use_case_detail: JSON.stringify(use_case_detail),
  }
}
