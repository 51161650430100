/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { orderBy, uniqBy } from 'lodash'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { EuiBasicTable, EuiFlexGroup, EuiFlexItem, EuiSkeletonText, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'
import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-user-lib/hooks/list'
import type { AnyUserProject } from '@modules/project-user-api/types'
import { projectTypeColumn } from '@modules/project-components/ProjectList/columns'
import { projectsUrl } from '@modules/project-user-lib/urls'
import usePermissions from '@modules/permissions-lib/hooks'
import { useListRegionsQuery } from '@modules/cluster-user-lib/hooks'
import type { MultiErrorResponse } from '@modules/project-user-api/v1/types'

import { useOnboardingTokenFromProfile } from '../../discovery-questions-lib/hooks'

import { getActionsColumn, getProjectNameColumn, regionColumn } from './columns'

import type { QueryObserverLoadingErrorResult } from 'react-query'

type Props = {
  limit?: number
}

const UserProjectList: React.FunctionComponent<Props> = ({ limit = Infinity }) => {
  const regionsQuery = useListRegionsQuery()
  const { data: regions, isLoading: regionsIsLoading } = regionsQuery
  const elasticsearchQuery = useListElasticsearchProjectsQuery({
    enabled: usePermissions([{ type: 'project-elasticsearch', action: 'list' }]).hasPermissions,
  })
  const observabilityQuery = useListObservabilityProjectsQuery({
    enabled: usePermissions([{ type: 'project-observability', action: 'list' }]).hasPermissions,
  })
  const securityQuery = useListSecurityProjectsQuery({
    enabled: usePermissions([{ type: 'project-security', action: 'list' }]).hasPermissions,
  })

  const queries = [elasticsearchQuery, observabilityQuery, securityQuery]
  const successfulQueries = queries.filter((query) => query.isSuccess)
  const items: AnyUserProject[] = []

  for (const query of successfulQueries) {
    const dataItems = query.data.items
    items.push(...dataItems)
  }

  const sortedItems = orderBy(items, 'name', 'asc')

  const visibleItems = limit ? sortedItems.slice(0, limit) : sortedItems
  const onboardingToken = useOnboardingTokenFromProfile(visibleItems.length)

  return (
    <div>
      {renderErrors()}
      {renderList()}
    </div>
  )

  function renderErrors() {
    const failedQueries = queries.filter((query) => query.isError) as Array<
      QueryObserverLoadingErrorResult<unknown, MultiErrorResponse>
    >

    const allErrors = failedQueries.map((query) => query.error.errors).flat()
    // If multiple queries failed, chances are it's a connection issue, so let's
    // not bash them over the head with three identical errors.

    const allUniqueErrors = uniqBy(allErrors, 'code')

    const errorsToDisplay = allUniqueErrors.filter(
      (error) => error.code !== 'projects.list_projects.forbidden',
    )

    return (
      <Fragment>
        {errorsToDisplay.map((error, i) => (
          <Fragment key={i}>
            <CuiAlert type='danger'>{error.message}</CuiAlert>
            <EuiSpacer size='m' />
          </Fragment>
        ))}
      </Fragment>
    )
  }

  function renderList() {
    const isLoading = queries.some((query) => query.isLoading)

    if (isLoading || regionsIsLoading) {
      return <EuiSkeletonText />
    }

    return (
      <Fragment>
        <EuiBasicTable
          tableLayout='auto'
          rowHeader='name'
          items={visibleItems}
          columns={[
            getProjectNameColumn(onboardingToken),
            { ...projectTypeColumn, width: '25%' },
            { ...regionColumn(regions), width: '25%' },
            { ...getActionsColumn(onboardingToken), width: '12em' },
          ]}
        />
        {sortedItems.length > limit ? (
          <Fragment>
            <EuiSpacer size='m' />
            <EuiFlexGroup alignItems='center' justifyContent='center'>
              <EuiFlexItem grow={false}>
                <Link to={projectsUrl()}>
                  <FormattedMessage id='portal-project-list' defaultMessage='View all projects' />
                </Link>
              </EuiFlexItem>
            </EuiFlexGroup>
          </Fragment>
        ) : null}
      </Fragment>
    )
  }
}

export default UserProjectList
