/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { AllowedSchemaTypes } from '@elastic/ebt'
import type { IAnalyticsClient } from '@elastic/ebt/client/src/analytics_client'

type SchemaPropsMap = {
  [keyword: string]: any
}

export class EventRegistry<T extends string, S extends SchemaPropsMap> {
  private analytics: IAnalyticsClient

  private eventType: T

  private schemaProps: {
    [key in keyof S]: { keywordType?: AllowedSchemaTypes | 'pass_through'; description: string }
  }

  constructor({
    analytics,
    eventType,
    schemaProps,
  }: {
    analytics: IAnalyticsClient
    eventType: T
    schemaProps: {
      [key in keyof S]: { keywordType?: AllowedSchemaTypes | 'pass_through'; description: string }
    }
  }) {
    this.analytics = analytics
    this.eventType = eventType
    this.schemaProps = schemaProps
  }

  private generateSchema() {
    const schema: {
      [key: string]: { type: AllowedSchemaTypes | 'pass_through'; _meta: { description: string } }
    } = {}

    for (const keyword in this.schemaProps) {
      if (this.schemaProps.hasOwnProperty(keyword)) {
        schema[keyword] = {
          type: this.schemaProps[keyword].keywordType || 'keyword',
          _meta: {
            description: this.schemaProps[keyword].description || '',
          },
        }
      }
    }

    return {
      eventType: this.eventType,
      schema,
    }
  }

  public registerEvent() {
    this.analytics.registerEventType(this.generateSchema())
  }

  public reportEvent(value: { [key in keyof S]: any }): void {
    type ReportEventType = {
      [key in keyof S]: any
    }
    this.analytics.reportEvent<ReportEventType>(this.eventType, value)
  }
}
