/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'

import history from '@modules/utils/history'
import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'
import { useSaasUser } from '@modules/profile-lib/hooks'
import { doesSaasEntityHaveExpiredTrial } from '@modules/profile-lib/trial'
import { portalUrl } from '@modules/utils/rootUrls'

import { useFlagsWhenLoaded } from '../launchdarkly'
import { ResourceType } from '../discovery-questions-lib/utils'
import {
  isServerlessOnboardingToken,
  isStatefulOnboardingToken,
} from '../discovery-questions-lib/steps'
// eslint-disable-next-line import/no-restricted-paths
import UserConsoleAppLoadingRoot from '../../public/components/AppLoadingRoot/UserConsoleAppLoadingRoot'

import type { FunctionComponent } from 'react'

export function handleOnboardingRedirect({
  onboardingToken,
  onboardingResourceFlag,
  hasExpiredTrial,
}: {
  onboardingToken: string | null | undefined
  onboardingResourceFlag: string
  hasExpiredTrial: boolean
}): void {
  if (hasExpiredTrial) {
    // Handle an edge case where someone revisits (e.g. through "Sign Up with
    // Google" button) the onboarding experience even though they have an
    // expired trial. In this case we don't want to go through onboarding
    // again, so just bail out.
    history.push(portalUrl())
    return
  }

  const isServerless = isServerlessOnboardingToken(onboardingToken)
  const isStateful = isStatefulOnboardingToken(onboardingToken)

  if ((isServerless && isStateful) || (!isServerless && !isStateful)) {
    const path =
      onboardingResourceFlag === ResourceType.Serverless
        ? '/onboarding/serverless'
        : '/onboarding/stateful'

    history.push(`${path}${onboardingToken ? `?onboarding_token=${onboardingToken}` : ''}`)
  } else if (isServerless && !isStateful) {
    history.push(
      `/onboarding/serverless${onboardingToken ? `?onboarding_token=${onboardingToken}` : ''}`,
    )
  } else if (isStateful && !isServerless) {
    history.push(
      `/onboarding/stateful${onboardingToken ? `?onboarding_token=${onboardingToken}` : ''}`,
    )
  }
}

const RedirectOnboarding: FunctionComponent = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const onboardingToken = queryParams.get('onboarding_token')
  const [isFlagUsable, flags] = useFlagsWhenLoaded()
  const isServerlessFeatureEnabled = useConfig(Feature.serverless)

  const saasUser = useSaasUser()
  const hasExpiredTrial = saasUser && doesSaasEntityHaveExpiredTrial(saasUser)

  useEffect(() => {
    if (hasExpiredTrial === undefined) {
      return // avoid this logic racing the ability to know the answer
    }

    if (!isServerlessFeatureEnabled) {
      handleOnboardingRedirect({
        onboardingToken,
        onboardingResourceFlag: ResourceType.Stateful,
        hasExpiredTrial,
      })
    }

    if (isFlagUsable) {
      handleOnboardingRedirect({
        onboardingToken,
        onboardingResourceFlag: flags.onboardingResource,
        hasExpiredTrial,
      })
    }
  }, [onboardingToken, isFlagUsable, flags, hasExpiredTrial])

  return <UserConsoleAppLoadingRoot />
}

export { RedirectOnboarding }
