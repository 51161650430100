/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import { EuiText, EuiSpacer, EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { addToast } from '@modules/cui/Toasts'
import { getResponseStatus } from '@modules/utils/ajax'
import { AjaxRequestError } from '@modules/ui-types'

import DocLink from '@/components/DocLink'
import CustomerOnlyAction from '@/components/User/CustomerOnlyAction'
import PrepaidCreditActivationModal from '@/components/User/BillingOverview/PrepaidAccountDetailsPanel/AddEcuCredits/PrepaidCreditActivationModal'

import type { OnSubmitProps } from '@/components/User/BillingOverview/PrepaidAccountDetailsPanel/AddEcuCredits/PrepaidCreditActivationModal/types'
import type { AllProps } from './types'

const messages = defineMessages({
  emptyActivationCodeError: {
    id: 'code-activation.empty-code-error',
    defaultMessage: 'Activation code required, check your email.',
  },
  invalidActivationCodeError: {
    id: 'code-activation.invalid-code-error',
    defaultMessage: 'Invalid code. Verify code and contact support, if needed.',
  },
  submittedActivationCodeMessage: {
    id: 'code-activation.submitted-message',
    defaultMessage: 'Your credits have been added to your account.',
  },
})

const AddEcuCredits = (props: AllProps) => {
  const [isAddActivationCodeModalOpen, setIsAddActivationCodeModalOpen] = useState<boolean>(false)
  const [activationCodeFormError, setActivationCodeFormError] = useState<string | undefined>() // code activation error is displayed under input
  const [activationCodeGeneralError, setActivationCodeGeneralError] = useState<boolean>(false) // general error is displayed in Callout under form
  const { formatMessage } = useIntl()

  const closeCodeActivationModal = (): void => {
    resetCodeActivationError()

    props.resetActivateLineItemsRequest()

    setIsAddActivationCodeModalOpen(false)
  }

  const resetCodeActivationError = () => {
    if (activationCodeFormError) {
      setActivationCodeFormError(undefined)
    }

    if (activationCodeGeneralError) {
      setActivationCodeGeneralError(false)
    }
  }

  const submitActivationCode = async ({
    activation_code,
    subscription_level,
  }: OnSubmitProps): Promise<void> => {
    // Validation before request - Code cannot be empty, If it is empty, do not send the request
    if (!activation_code) {
      setActivationCodeFormError(formatMessage(messages.emptyActivationCodeError))

      return
    }

    try {
      // Reset all errors everytime we re-submit form
      resetCodeActivationError()

      await props.activateLineItems({
        organization_id: props.organizationId,
        activation_code,
        subscription_level,
      })

      // We need to refetch line-items list
      props.refetchLineItems()

      addToast({
        color: 'success',
        dataTestSubj: 'code-activation.submitted-message',
        title: formatMessage(messages.submittedActivationCodeMessage),
      })
    } catch (err) {
      if (err instanceof AjaxRequestError) {
        const status = getResponseStatus(err)

        // Based on Swagger definition
        // 400 - Invalid code
        if (status === 400) {
          setActivationCodeFormError(formatMessage(messages.invalidActivationCodeError))

          return
        }
      }

      // Any other error - lets display general error
      setActivationCodeGeneralError(true)
    }
  }

  return (
    <Fragment>
      <EuiSpacer size='xl' />

      <EuiFlexGroup alignItems='center' gutterSize='m'>
        <EuiFlexItem grow={false}>
          <EuiText size='s' data-test-id='creditActivationDocLink'>
            <DocLink link='billingPrepaidConsumptionModel' favorSaasContext={true}>
              <FormattedMessage
                id='prepaid-balance-panel.explore-pre-paid-options-link'
                defaultMessage='Explore prepaid consumption options'
              />
            </DocLink>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <CustomerOnlyAction>
            <EuiButton
              onClick={() => setIsAddActivationCodeModalOpen(true)}
              data-test-id='addActivationCodeButton'
            >
              <FormattedMessage
                id='prepaid-balance-panel.add-activation-code'
                defaultMessage='Add ECU credits'
              />
            </EuiButton>
          </CustomerOnlyAction>
        </EuiFlexItem>
      </EuiFlexGroup>

      {isAddActivationCodeModalOpen && (
        <PrepaidCreditActivationModal
          isSubmitted={props.activateLineItemsRequest.isDone}
          onClose={closeCodeActivationModal}
          onSubmit={submitActivationCode}
          isLoading={props.activateLineItemsRequest.inProgress}
          inputError={activationCodeFormError}
          generalError={activationCodeGeneralError}
          onCodeChange={resetCodeActivationError} // we want to clean errors when input change
          data-test-id='creditActivationModal'
        />
      )}
    </Fragment>
  )
}

export default AddEcuCredits
