/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { useProfile } from '@modules/profile-lib/hooks'
import type { MiddlewareComponent } from '@modules/app/types'
import ProfileProvider from '@modules/profile-lib/ProfileProvider'

import BillingDetailsProvider from './BillingDetailsProvider'

const BillingDetailsMiddleware: MiddlewareComponent = ({ children }) => {
  const profile = useProfile()

  return (
    <BillingDetailsProvider organization_id={profile?.organization_id}>
      {children}
    </BillingDetailsProvider>
  )
}

BillingDetailsMiddleware.middleware = {
  displayName: 'BillingDetailsMiddleware',
  dependencies: [ProfileProvider],
}

export default BillingDetailsMiddleware
