/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiText, EuiCallOut, EuiLink } from '@elastic/eui'

import type { AsyncRequestState, EsProxyResponseConsole, StackDeployment } from '@modules/ui-types'
import { isNonEmptyArray } from '@modules/ts-utils'

import { getConfigForKey } from '@/store'
import {
  getPlanInfo,
  getFirstEsClusterFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'

import DocLink from '../DocLink'

import Request from './Request'
import Output from './Output'

import type { ConsoleRequestState } from '../../reducers/apiConsole'

import './apiConsole.scss'

type Props = {
  deployment: StackDeployment | null
  consoleRequest?: ConsoleRequestState | null
  consoleRequestHistory: ConsoleRequestState[]
  consoleResponse: EsProxyResponseConsole | null
  queryDeploymentProxyRequest: AsyncRequestState
  clearAPIConsoleHistory: () => void
  clearDeploymentProxyResponse: (deploymentId: string) => void
  queryDeploymentProxyForConsole: (
    deployment: StackDeployment,
    consoleRequest: ConsoleRequestState,
  ) => void
  setAPIConsoleRequest: (request: ConsoleRequestState, deployment: StackDeployment) => void
}

class APIConsole extends Component<Props> {
  componentDidMount() {
    this.setInitialRequest()
  }

  render() {
    const {
      clearDeploymentProxyResponse,
      clearAPIConsoleHistory,
      deployment,
      consoleRequest,
      consoleRequestHistory,
      consoleResponse,
      queryDeploymentProxyRequest,
      queryDeploymentProxyForConsole,
      setAPIConsoleRequest,
    } = this.props

    const showSupportInstructions = getConfigForKey(`SHOW_SUPPORT_INSTRUCTIONS`) ?? false

    if (!deployment) {
      return null
    }

    const firstEs = getFirstEsClusterFromGet({ deployment })

    if (firstEs && !getPlanInfo({ resource: firstEs })) {
      return null
    }

    if (consoleRequest == null) {
      return null
    }

    return (
      <Fragment>
        {showSupportInstructions && this.renderPrivacyWarning()}
        <div className='apiConsole'>
          <EuiText>
            <FormattedMessage
              id='api-console.overview'
              defaultMessage='Perform operations-related tasks from this console. You can run search queries, review the list of snapshots, check the health of your clusters, and { link }.'
              values={{
                link: (
                  <DocLink link='apiConsoleDocLink'>
                    <FormattedMessage id='api-console.overview.more' defaultMessage='more' />
                  </DocLink>
                ),
              }}
            />
          </EuiText>

          <EuiSpacer size='m' />

          <Request
            deployment={deployment}
            request={consoleRequest}
            requestHistory={consoleRequestHistory}
            clearDeploymentProxyResponse={clearDeploymentProxyResponse}
            clearAPIConsoleHistory={clearAPIConsoleHistory}
            onChange={setAPIConsoleRequest}
            inProgress={queryDeploymentProxyRequest.inProgress}
            submitQueryToDeploymentProxy={(request) => {
              queryDeploymentProxyForConsole(deployment, request)
            }}
          />

          <EuiSpacer size='m' />

          <Output
            requestSettings={consoleRequest}
            request={queryDeploymentProxyRequest}
            response={consoleResponse}
          />
        </div>
      </Fragment>
    )
  }

  renderPrivacyWarning() {
    return (
      <Fragment>
        <EuiCallOut
          data-test-id='api-console.privacy-warning'
          color='warning'
          iconType='alert'
          title={
            <FormattedMessage
              id='apiConsole.privacyWarning.title'
              defaultMessage='Privacy warning'
            />
          }
        >
          <FormattedMessage
            id='apiConsole.privacyWarning'
            defaultMessage={
              'Written customer consent is required when accessing customer data, including regular indices and system indices. See Handling Customer Data {wiki} for more guidance.'
            }
            values={{
              wiki: (
                <EuiLink href='https://ela.st/ac-api-consense' target='_blank'>
                  <FormattedMessage
                    id='apiConsole.handling-customer-data-wiki'
                    defaultMessage='wiki'
                  />
                </EuiLink>
              ),
            }}
          />
        </EuiCallOut>
        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  setInitialRequest() {
    const { setAPIConsoleRequest, deployment } = this.props

    if (!deployment) {
      return
    }

    setAPIConsoleRequest(this.getInitialRequest(), deployment)
  }

  getInitialRequest() {
    const { consoleRequestHistory } = this.props

    if (isNonEmptyArray(consoleRequestHistory)) {
      return consoleRequestHistory[0]
    }

    const initialRequest: ConsoleRequestState = {
      method: `GET`,
      path: `/_search`,
      body: ``,
      advancedMode: false,
      filterBy: `regex`,
      filterRegex: ``,
      filterJq: ``,
      invertFilter: false,
    }

    return initialRequest
  }
}

export default APIConsole
