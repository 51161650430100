/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useEffect } from 'react'

import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'

import { setCookie } from './cookies'

export const useSetBillingModelCookie = () => {
  const { data } = useBillingDetails()
  const cloudBillingModelCookieKey = `ec_billing_model`
  const settings = { expires: 29, sameSite: `Lax` as const }

  useEffect(() => {
    if (data?.billing_model) {
      setCookie(cloudBillingModelCookieKey, data.billing_model, { settings, topLevel: true })
    }
  })
}
