/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiListGroup, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'

import { getMarketplaceDisplayName } from '@modules/billing-lib/billingDetails/utils'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'

import DocLink, { DocLinkKey } from '@/components/DocLink'

import type { FunctionComponent } from 'react'

interface Props {
  hasBorder?: boolean
  showEcuMessage?: boolean
}

const SuggestedContentPanel: FunctionComponent<Props> = ({ hasBorder, showEcuMessage }) => {
  const items = [
    {
      label: (
        <DocLink link='billingFAQ' favorSaasContext={true}>
          <FormattedMessage id='suggested-content.billing-faq' defaultMessage='Billing FAQs' />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    },
    {
      label: (
        <DocLink link='accountBilling' favorSaasContext={true}>
          <FormattedMessage
            id='suggested-content.billing-account-faq'
            defaultMessage='Your account and billing'
          />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    },
    {
      label: (
        <DocLink link='billingCalculationFAQ' favorSaasContext={true}>
          <FormattedMessage
            id='suggested-content.billing-calculation-faq'
            defaultMessage='How my bill is calculated'
          />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    },
    {
      label: (
        <DocLink link='stopBillingCharges' favorSaasContext={true}>
          <FormattedMessage
            id='suggested-content.billing.stop-charges'
            defaultMessage='How to stop charges for a deployment'
          />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    },
  ]

  if (showEcuMessage) {
    items.push({
      label: (
        <DocLink link='billingECU' favorSaasContext={true}>
          <FormattedMessage
            id='suggested-content.billing.ecus'
            defaultMessage='What are Elastic Consumption Units (ECU)'
          />
        </DocLink>
      ),
      'data-test-id': 'suggested-content-panel.link',
    })
  }

  const channel = useBillingDetails()?.data?.channel

  if (channel && ['aws', 'gcp', 'azure'].includes(channel)) {
    const linkKey = `${channel}Marketplace` as DocLinkKey

    items.unshift({
      label: (
        <DocLink link={linkKey} favorSaasContext={true}>
          <FormattedMessage
            id='suggested-content.billing.marketplace-doc'
            defaultMessage='{csp} Marketplace'
            values={{ csp: getMarketplaceDisplayName(channel) }}
          />
        </DocLink>
      ),
      'data-test-id': `suggested-content-panel.link.${channel}`,
    })
  }

  return (
    <EuiPanel
      paddingSize='l'
      hasBorder={hasBorder}
      data-test-id='suggested-content-card'
      grow={false}
    >
      <EuiText>
        <h6>
          <FormattedMessage
            id='billing-history.suggested-content-title'
            defaultMessage='Suggested content'
          />
        </h6>
      </EuiText>

      <EuiSpacer size='m' />

      <EuiListGroup flush={true} listItems={items} />
    </EuiPanel>
  )
}

export default SuggestedContentPanel
