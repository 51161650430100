/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ElasticsearchClusterTrustSettings } from '@modules/cloud-api/v1/types'
import type {
  AnyTrustRelationship,
  ApmId,
  KibanaId,
  RegionId,
  SliderInstanceType,
} from '@modules/ui-types'
import Feature from '@modules/utils/feature'

import { isSliderInstanceType } from '@/lib/sliders/support'

import { isFeatureActivated } from '../store'

import {
  getTrustRelationshipId,
  getTrustRelationshipType,
} from './stackDeployments/trustRelationships'

type UrlBuilder = (...params: string[]) => string

export function userSettingsUrl(): string {
  return isFeatureActivated(Feature.cloudPortalEnabled) ? `/user/settings` : `/settings`
}

export function regionsUrl(): string {
  return `/platform`
}

export function regionUrl(regionId: RegionId): string {
  return `/region/${regionId}`
}

export function platformUrl(regionId: RegionId): string {
  const region = regionUrl(regionId)
  return `${region}/platform`
}

export function activityFeedUrl(): string {
  return `/activity-feed`
}

export function resetPasswordUrl(): string {
  return `/account/reset-password`
}

export function registerUrl(): string {
  return `/registration`
}

export function marketplaceRegisterUrl(): string {
  return `/marketplace-registration`
}

export function marketplaceConversionUrl(): string {
  return `/marketplace-conversion`
}

export function forgotPasswordUrl(): string {
  return `/forgot`
}

export function regionSettingsUrl(regionId: RegionId): string {
  const region = regionUrl(regionId)
  return `${region}/settings`
}

export function regionSecurityUrl(regionId: RegionId): string {
  const region = regionUrl(regionId)
  return `${region}/security`
}

export function snapshotRepositoriesUrl(regionId: RegionId): string {
  const region = regionUrl(regionId)
  return `${region}/repositories`
}

export function createSnapshotRepositoryUrl(regionId: RegionId): string {
  const repositories = snapshotRepositoriesUrl(regionId)
  return `${repositories}/create`
}

export function editSnapshotRepositoryUrl(regionId, repositoryId: string): string {
  const repositories = snapshotRepositoriesUrl(regionId)
  return `${repositories}/${repositoryId}/edit`
}

export function managementUrl(): string {
  return `/manage`
}

export function manageRegionUrl(regionId: RegionId): string {
  return `${regionUrl(regionId)}/manage`
}

export function manageNativeUsersUrl(regionId: RegionId): string {
  return `${manageRegionUrl(regionId)}/users`
}

export function manageAllUsersApiKeysUrl(regionId: RegionId): string {
  return `${manageRegionUrl(regionId)}/keys`
}

export function authenticationProvidersUrl(regionId: RegionId): string {
  return `${manageRegionUrl(regionId)}/authentication-providers`
}

export function addActiveDirectoryAuthenticationProviderUrl(regionId: RegionId): string {
  return `${manageRegionUrl(regionId)}/authentication-providers/active-directory/create`
}

export function editActiveDirectoryAuthenticationProviderUrl(
  regionId: RegionId,
  realmId: string,
): string {
  return `${manageRegionUrl(regionId)}/authentication-providers/active-directory/${realmId}`
}

export function addLdapAuthenticationProviderUrl(regionId: RegionId): string {
  return `${manageRegionUrl(regionId)}/authentication-providers/ldap/create`
}

export function editLdapAuthenticationProviderUrl(regionId: RegionId, realmId: string): string {
  return `${manageRegionUrl(regionId)}/authentication-providers/ldap/${realmId}`
}

export function addSamlAuthenticationProviderUrl(regionId: RegionId): string {
  return `${manageRegionUrl(regionId)}/authentication-providers/saml/create`
}

export function editSamlAuthenticationProviderUrl(regionId: RegionId, realmId: string): string {
  return `${manageRegionUrl(regionId)}/authentication-providers/saml/${realmId}`
}

export function elasticStackVersionsUrl(regionId: RegionId): string {
  const region = regionUrl(regionId)
  return `${region}/elastic-stack`
}

export function platformTrustManagementUrl(regionId: RegionId): string {
  const region = regionUrl(regionId)
  return `${region}/trust-management`
}

export function elasticStackVersionUrl(regionId: RegionId, versionId: string): string {
  const versions = elasticStackVersionsUrl(regionId)
  return `${versions}/${versionId}`
}

export function deploymentsUrl(): string {
  return `/deployments`
}

/* `resolveDeploymentUrlForEsCluster` is a bit of a hack: when we only have an ES cluster ID,
 * and we want to redirect to a deployment page, we use this function to decide
 * where to send users.
 *
 * we need to go through the cluster resolver:
 *   /deployments/resolve/cluster/us-east-1/a00/snapshots/scheduled-1337
 *
 * the resolver ends up redirecting the customer to the actual deployment:
 *   /deployments/b00/snapshots/scheduled-1337
 *
 * this function is designed in this way so that we can build reasonable URLs instead of
 * relying on some sort of "action" map for each kind of URL, or duplicating all url
 * builders we need for the Deployments API.
 */
export function resolveDeploymentUrlForEsCluster(
  buildDeploymentUrl: UrlBuilder,
  regionId: RegionId,
  esClusterId: string,
  ...rest: string[]
): string {
  const params = [':deploymentId', ...rest]
  const baseUrl = deploymentUrl(':deploymentId')
  const absoluteTargetUrl = buildDeploymentUrl(...params)

  if (!absoluteTargetUrl.startsWith(baseUrl)) {
    throw new Error(
      `resolveDeploymentUrlForEsCluster expects buildDeploymentUrl to produce deployment routes`,
    )
  }

  const relativeTargetUrl = absoluteTargetUrl.replace(baseUrl, ``)
  const baseResolveUrl = `/deployments/resolve/cluster/${regionId}/${esClusterId}`

  if (!relativeTargetUrl) {
    return baseResolveUrl
  }

  return `${baseResolveUrl}${relativeTargetUrl}`
}

export function deploymentUrl(deploymentId: string): string {
  return `/deployments/${deploymentId}`
}

export function deploymentGettingStartedUrl(deploymentId: string): string {
  return `/deployments/${deploymentId}/getting-started`
}

export function deploymentEditUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/edit`
}

function deploymentMonitoringUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/monitoring`
}

export function deploymentHealthStatusUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/health`
}

export function deploymentHealthProblemDetailsUrl(deploymentId: string, issue: string): string {
  const deployment = deploymentHealthStatusUrl(deploymentId)
  return `${deployment}/${issue}`
}

export function deploymentAdvancedEditUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/edit/advanced`
}

export function deploymentEditSliderUrl(
  deploymentId: string,
  sliderInstanceType: SliderInstanceType,
): string {
  const deploymentEdit = deploymentEditUrl(deploymentId)
  return `${deploymentEdit}#${sliderInstanceType}`
}

export function deploymentActivityUrl(deploymentId: string = `:deploymentId`): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/activity`
}

export function deploymentIlmMigrationUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/migrate-to-index-lifecycle-management`
}

export function sliderActivityUrl(
  deploymentId: string,
  sliderInstanceType: SliderInstanceType,
): string {
  const deploymentActivity = deploymentActivityUrl(deploymentId)
  return `${deploymentActivity}/${sliderInstanceType}`
}

export function deploymentActivityElasticsearchUrl(deploymentId: string): string {
  return sliderActivityUrl(deploymentId, `elasticsearch`)
}

export function securityUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/security`
}

export function manageDeploymentTrustRelationshipUrl(
  deploymentId: string,
  trustRelationship: AnyTrustRelationship,
): string {
  const deployment = deploymentUrl(deploymentId)
  const trustRelationshipType = getTrustRelationshipType(trustRelationship)
  const trustRelationshipId = getTrustRelationshipId({ trustRelationship })
  return `${deployment}/security/trust/${trustRelationshipType}/${trustRelationshipId}`
}

export function createDeploymentTrustRelationshipUrl(
  deploymentId: string,
  trustRelationshipType: keyof ElasticsearchClusterTrustSettings | undefined,
): string {
  const deployment = deploymentUrl(deploymentId)

  if (!trustRelationshipType) {
    return `${deployment}/security/trust/create`
  }

  return `${deployment}/security/trust/${trustRelationshipType}`
}

export function operationsUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/operations`
}

function indexCurationUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/index-curation`
}

export function performanceMetricsUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/metrics`
}

function elasticsearchLogsUrl(deploymentId: string): string {
  const deploymentEs = elasticsearchUrl(deploymentId)
  return `${deploymentEs}/logs`
}

export function heapDumpsUrl(deploymentId: string): string {
  const operations = operationsUrl(deploymentId)
  return `${operations}/heap-dumps`
}

export function logsMonitoringUrl(deploymentId: string) {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/logs-metrics`
}

export function kibanaUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/kibana`
}

export function resolveDeploymentUrlForAnyCluster({
  regionId,
  sliderInstanceType,
  stackDeploymentId,
  clusterId,
}: {
  regionId: RegionId
  sliderInstanceType?: SliderInstanceType
  clusterId: string
  stackDeploymentId?: string
}): string {
  if (stackDeploymentId && sliderInstanceType && isSliderInstanceType(sliderInstanceType)) {
    return sliderUrl(stackDeploymentId, sliderInstanceType)
  }

  if (sliderInstanceType === `elasticsearch`) {
    return resolveDeploymentUrlForEsCluster(elasticsearchUrl, regionId, clusterId)
  }

  if (sliderInstanceType === `kibana`) {
    return resolveDeploymentUrlForKibanaCluster(regionId, clusterId)
  }

  if (sliderInstanceType === `apm`) {
    return resolveDeploymentUrlForApmCluster(regionId, clusterId)
  }

  if (sliderInstanceType === `enterprise_search`) {
    return resolveDeploymentUrlForApmCluster(regionId, clusterId)
  }

  return resolveDeploymentUrlForEsCluster(deploymentUrl, regionId, clusterId)
}

function resolveDeploymentUrlForKibanaCluster(regionId: RegionId, kibanaId: KibanaId): string {
  return `/deployments/resolve/kibana/${regionId}/${kibanaId}`
}

function resolveDeploymentUrlForApmCluster(regionId: RegionId, apmId: ApmId): string {
  return `/deployments/resolve/apm/${regionId}/${apmId}`
}

function apmUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/apm`
}

function integrationsServerUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/integrations_server`
}

export function sliderUrl(deploymentId: string, sliderInstanceType: SliderInstanceType): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/${sliderInstanceType}`
}

function elasticsearchUrl(deploymentId: string): string {
  const deployment = deploymentUrl(deploymentId)
  return `${deployment}/elasticsearch`
}

export function deploymentUrls(deploymentId: string) {
  const root = deploymentUrl(deploymentId)

  return {
    advancedEdit: deploymentAdvancedEditUrl(deploymentId),
    apm: apmUrl(deploymentId),
    appsearch: sliderUrl(deploymentId, `appsearch`),
    console: clusterApiConsoleUrl(deploymentId),
    deploymentActivity: deploymentActivityUrl(deploymentId),
    edit: deploymentEditUrl(deploymentId),
    healthStatus: deploymentHealthStatusUrl(deploymentId),
    elasticsearch: elasticsearchUrl(deploymentId),
    enterprise_search: sliderUrl(deploymentId, `enterprise_search`),
    indexManagement: indexCurationUrl(deploymentId),
    integrations_server: integrationsServerUrl(deploymentId),
    kibana: kibanaUrl(deploymentId),
    logs: elasticsearchLogsUrl(deploymentId),
    metrics: performanceMetricsUrl(deploymentId),
    operations: operationsUrl(deploymentId),
    root,
    security: securityUrl(deploymentId),
    snapshots: clusterSnapshotsUrl(deploymentId),
    monitoring: deploymentMonitoringUrl(deploymentId),
  }
}

export function clusterSnapshotsUrl(deploymentId: string): string {
  return `${elasticsearchUrl(deploymentId)}/snapshots`
}

export function clusterApiConsoleUrl(deploymentId: string): string {
  return `${elasticsearchUrl(deploymentId)}/console`
}

export function clusterSnapshotUrl(deploymentId: string, snapshotName: string): string {
  return `${elasticsearchUrl(deploymentId)}/snapshots/${snapshotName}`
}

export function createDeploymentUrl(regionId?: string | null): string {
  const qs = regionId ? `?regionId=${regionId}` : ''
  return `/deployments/create${qs}`
}

export function deploymentExtensionsUrl(): string {
  return `/deployment-features/extensions`
}

export function deploymentExtensionUrl(extensionId: string): string {
  return `/deployment-features/extensions/${extensionId}/edit`
}

export function deploymentExtensionCreateUrl(): string {
  return `/deployment-features/extensions/create`
}

export function topologyUrl(regionId: RegionId): string {
  const region = regionUrl(regionId)
  return `${region}/templates`
}

export function topologyDeploymentTemplatesUrl(regionId: RegionId): string {
  const regionTopology = topologyUrl(regionId)
  return `${regionTopology}/deployments`
}

export function topologyViewDeploymentTemplateUrl(regionId: RegionId, templateId: string): string {
  return `${topologyDeploymentTemplatesUrl(regionId)}/${templateId}`
}

export function topologyEditDeploymentTemplateUrl(regionId: RegionId, templateId: string): string {
  return `${topologyViewDeploymentTemplateUrl(regionId, templateId)}/edit`
}

export function createTopologyClusterTemplateUrl(regionId: RegionId): string {
  const regionTopology = topologyUrl(regionId)
  return `${regionTopology}/deployments/create`
}

export function topologyInstanceConfigurationsUrl(regionId: RegionId): string {
  const regionTopology = topologyUrl(regionId)
  return `${regionTopology}/instance-configurations`
}

export function topologyViewInstanceConfigurationUrl(
  regionId: RegionId,
  instanceId: string,
): string {
  const regionTopology = topologyUrl(regionId)
  return `${regionTopology}/instance-configurations/${instanceId}`
}

export function topologyEditInstanceConfigurationUrl(
  regionId: RegionId,
  instanceId: string,
): string {
  return `${topologyViewInstanceConfigurationUrl(regionId, instanceId)}/edit`
}

export function createTopologyNodeConfigurationUrl(regionId: RegionId): string {
  const regionTopology = topologyUrl(regionId)
  return `${regionTopology}/instance-configurations/create`
}

export function hostsUrl(regionId: RegionId): string {
  const region = regionUrl(regionId)
  return `${region}/hosts`
}

export function hostsAllocatorsUrl(regionId: RegionId): string {
  const hosts = hostsUrl(regionId)
  return `${hosts}/allocators`
}

export function hostsProxiesUrl(regionId: RegionId): string {
  const hosts = hostsUrl(regionId)
  return `${hosts}/proxies`
}

export function hostsControlPlanesUrl(regionId: RegionId): string {
  const hosts = hostsUrl(regionId)
  return `${hosts}/control-planes`
}

export function hostUrl(regionId: RegionId, hostId: string): string {
  const hosts = hostsUrl(regionId)
  return `${hosts}/${hostId}`
}

export function hostAllocatorUrl(regionId: RegionId, hostId: string): string {
  const host = hostUrl(regionId, hostId)
  return `${host}/allocator`
}

export function hostAllocatorMoveNodesUrl(regionId: RegionId, hostId: string): string {
  const allocator = hostAllocatorUrl(regionId, hostId)
  return allocator
}

export function hostProxyUrl(regionId: RegionId, hostId: string): string {
  const host = hostUrl(regionId, hostId)
  return `${host}/proxy`
}

export function hostControlPlaneUrl(regionId: RegionId, hostId: string): string {
  const host = hostUrl(regionId, hostId)
  return `${host}/control-plane`
}

export function deploymentFeaturesUrl(): string {
  return `/deployment-features`
}

export function supportUrl(): string {
  return `/support`
}

export function apiKeysUrl(): string {
  return `/keys`
}

export function orgSecurityUrl(): string {
  return '/account/idp'
}

export function adminconsolesUrl(regionId: string): string {
  return `${regionUrl(regionId)}/admin-consoles`
}

export function adminconsoleOverviewUrl(regionId: string, adminconsoleId: string): string {
  return `${adminconsolesUrl(regionId)}/${adminconsoleId}`
}

export function containerSetsUrl(regionId: RegionId): string {
  const region = regionUrl(regionId)
  return `${region}/container-sets`
}

export function containerSetUrl(regionId: RegionId, containerSetId: string): string {
  const containerSets = containerSetsUrl(regionId)
  return `${containerSets}/${containerSetId}`
}

export function containerUrl(
  regionId: RegionId,
  containerSetId: string,
  containerId: string,
): string {
  const containerSet = containerSetUrl(regionId, containerSetId)
  return `${containerSet}/containers/${containerId}`
}

export function serverlessDiscoveryQuestions({
  onboardingToken,
  fromURI,
}: {
  onboardingToken?: string
  fromURI?: string
}): string {
  const fromURIParam = fromURI ? `?fromURI=${encodeURIComponent(fromURI)}` : ``

  if (!onboardingToken) {
    return `/onboarding/serverless${fromURIParam}`
  }

  return `/onboarding/serverless?onboarding_token=${onboardingToken}&${fromURIParam.slice(1)}`
}

export function redirectDiscoveryQuestions({
  onboardingToken,
  fromURI,
}: {
  onboardingToken?: string
  fromURI?: string
}): string {
  const fromURIParam = fromURI ? `?fromURI=${encodeURIComponent(fromURI)}` : ``

  if (!onboardingToken) {
    return `/onboarding/redirect${fromURIParam}`
  }

  return `/onboarding/redirect?onboarding_token=${onboardingToken}${
    fromURIParam !== '' ? `&${fromURIParam.slice(1)}` : ''
  }`
}

export function notFoundUrl(): string {
  return `/not-found`
}
