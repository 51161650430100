/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

// eslint-disable-next-line import/no-restricted-paths
import { getDeploymentResourceEndpoint } from '@/lib/stackDeployments/selectors/endpoints'

// eslint-disable-next-line import/no-restricted-paths
import { ResourceType } from '../../discovery-questions-lib/utils'
import { useKibanaDeeplinkForCurrentResource } from '../../discovery-questions-lib/hooks'
import { makeSsoUrl } from '../../project-lib/kibanaLinks'
import { isValidOnboardingToken } from '../../discovery-questions-lib/steps'

import { isAttributesValidForRoute } from './helpers'

import type { UserProfileFromApi } from '../../ui-types'
import type { DeploymentGetResponse } from '../../cloud-api/v1/types'
import type { components } from '../../project-user-api/v1/types'

export const useKibanaLinks = ({
  profile,
  deployment,
  onboardingToken,
  project,
}: {
  profile?: { user: UserProfileFromApi }
  deployment?: DeploymentGetResponse
  onboardingToken?: string | null
  project?:
    | components['schemas']['ElasticsearchProject']
    | components['schemas']['ObservabilityProject']
    | components['schemas']['SecurityProject']
}) => {
  const attributesAvailable = isAttributesValidForRoute(deployment)
  const onboardingTokenValid = isValidOnboardingToken(onboardingToken) ? onboardingToken : undefined
  const kibanaDeepLinkServerless = useKibanaDeeplinkForCurrentResource(
    ResourceType.Serverless,
    profile?.user.is_trial ?? false,
    attributesAvailable,
    onboardingTokenValid,
  )
  const kibanaDeepLinkHosted = useKibanaDeeplinkForCurrentResource(
    ResourceType.Stateful,
    profile?.user.is_trial ?? false,
    attributesAvailable,
    onboardingTokenValid,
  )

  const deploymentLink =
    profile &&
    deployment &&
    getDeploymentResourceEndpoint({
      deployment: deployment as unknown as DeploymentGetResponse,
      sliderInstanceType: 'kibana',
      getDeepLink: kibanaDeepLinkHosted ? () => kibanaDeepLinkHosted : undefined,
    })
  const projectLink =
    profile && project && makeSsoUrl(project, { kibanaDeepLink: kibanaDeepLinkServerless })

  return { deploymentLink, projectLink }
}
