/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'

import type { UserProfile } from '@modules/ui-types'
import type { WithBillingDetailsProps } from '@modules/billing-lib/billingDetails/types'
import { isPrepaidConsumptionDirectResellerCustomer } from '@modules/billing-lib/billingDetails/utils'

import OrganizationPrepaids from '@/components/Organization/OrganizationPrepaids'
import SubscriptionManager from '@/components/User/BillingSubscription/components/SubscriptionManager'
import SuggestedContentPanel from '@/apps/userconsole/components/Billing/BillingOverview/SuggestedContentPanel'
import waves from '@/files/waves-lightblue.svg'

import PrepaidAccountDetailsPanel from '../BillingOverview/PrepaidAccountDetailsPanel'

import SubscriptionDetailsCard from './components/SubscriptionDetailsCard'
import PaymentDetailsCard from './components/PaymentDetailsCard'

import type { FunctionComponent } from 'react'

export interface Props extends WithBillingDetailsProps {
  profile: UserProfile
  showPrepaidDetails: boolean
}

const prepaidPanel = css({ minWidth: 342 })

const PrepaidConsumptionSubscription: FunctionComponent<Props> = ({
  profile,
  showPrepaidDetails,
  billingDetails,
}) => (
  <EuiFlexGroup gutterSize='m' wrap={true}>
    <EuiFlexItem grow={7}>
      <EuiFlexGroup>
        <EuiFlexItem css={prepaidPanel}>
          <SubscriptionDetailsCard
            subscriptionLevel={profile.level}
            title={
              isPrepaidConsumptionDirectResellerCustomer(billingDetails.data!) ? (
                <FormattedMessage
                  id='prepaid-consumption-subscription.title-reseller'
                  defaultMessage='Annual prepaid reseller subscription'
                />
              ) : (
                <FormattedMessage
                  id='prepaid-consumption-subscription.title'
                  defaultMessage='Annual prepaid subscription'
                />
              )
            }
            footer={
              <SubscriptionManager
                buttonText={
                  <FormattedMessage
                    id='billing-subscription.change-plan-cta'
                    defaultMessage='Change plan'
                  />
                }
              />
            }
            backgroundImage={waves}
          />
        </EuiFlexItem>
        <EuiFlexItem css={prepaidPanel}>
          <PaymentDetailsCard
            details={
              <FormattedMessage
                id='billing-subscription.payment-details'
                defaultMessage='Paid by purchase order'
              />
            }
            footer={
              <FormattedMessage
                id='billing-subscription.payment-footer'
                defaultMessage='To add more funds, contact your account sales executive.'
              />
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='xl' />

      <EuiFlexGroup>
        <EuiFlexItem css={prepaidPanel}>
          <PrepaidAccountDetailsPanel hasBorder={true} organizationId={profile.organization_id!} />
        </EuiFlexItem>
      </EuiFlexGroup>

      {showPrepaidDetails && (
        <Fragment>
          <EuiSpacer size='s' />

          <EuiHorizontalRule />

          <EuiSpacer size='s' />

          <OrganizationPrepaids organizationId={profile.organization_id} />
        </Fragment>
      )}
    </EuiFlexItem>

    <EuiFlexItem>
      <SuggestedContentPanel
        hasBorder={true}
        data-test-id='billing-details-summary.suggested-content'
        showEcuMessage={true}
      />
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default PrepaidConsumptionSubscription
