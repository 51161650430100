/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { DefaultSolutionView } from '@modules/deployment-creation-wizard/types'
import type { PlatformId } from '@modules/ui-types'

import { ONBOARDING_TOKEN_VALIDITY_WINDOW, SECURITY_USE_CASES_SIEM } from './constants'

import type { AnyUseCaseDetail, UseCaseDetailWithMigrationFromSiem } from './types'

const PlatformIdType: PlatformId[] = ['aws', 'gcp', 'azure']

export const isPlatformId = (platform?: string): platform is PlatformId =>
  platform ? Object.values(PlatformIdType).includes(platform as PlatformId) : false

export enum TrialIntentOptionsType {
  evaluate = 'evaluate',
  migrate = 'migrate',
  pricing_info = 'pricing_info',
  learn = 'learn',
  do_something_else = 'do_something_else',
}

export enum ResourceType {
  Stateful = 'stateful',
  Serverless = 'serverless',
}

export enum TrialIntentOptionsValueType {
  evaluate = 'Evaluate Elastic for my project or use case',
  migrate = 'Migrate an existing Elasticsearch environment',
  pricing_info = 'Get pricing information',
  learn = 'Learn more about Elastic',
  do_something_else = 'Do something else',
}

export const TRIAL_INTENTS_VALUES: {
  [key in TrialIntentOptionsType]: TrialIntentOptionsValueType
} = {
  evaluate: TrialIntentOptionsValueType.evaluate,
  migrate: TrialIntentOptionsValueType.migrate,
  pricing_info: TrialIntentOptionsValueType.pricing_info,
  learn: TrialIntentOptionsValueType.learn,
  do_something_else: TrialIntentOptionsValueType.do_something_else,
}

export enum UseCaseOptionsType {
  search = 'search',
  observability = 'observability',
  security = 'security',
  something_else = 'something_else',
}

export enum UseCaseOptionsValueType {
  search = 'Search',
  observability = 'Observability',
  security = 'Security',
  something_else = 'Something else',
}

export const USE_CASES_VALUES: {
  [key in UseCaseOptionsType]: UseCaseOptionsValueType
} = {
  search: UseCaseOptionsValueType.search,
  observability: UseCaseOptionsValueType.observability,
  security: UseCaseOptionsValueType.security,
  something_else: UseCaseOptionsValueType.something_else,
}
export const USE_CASES_ID = {
  search: UseCaseOptionsType.search,
  observability: UseCaseOptionsType.observability,
  security: UseCaseOptionsType.security,
  something_else: UseCaseOptionsType.something_else,
}

export enum ExperienceLevelOptionsType {
  new = 'new',
  experienced = 'experienced',
  an_expert = 'an_expert',
}

export enum ExperienceLevelOptionsValueType {
  new = 'New',
  experienced = 'Experienced',
  an_expert = 'An expert',
}

export const EXPERIENCE_LEVELS_VALUES: {
  [key in ExperienceLevelOptionsType]: ExperienceLevelOptionsValueType
} = {
  new: ExperienceLevelOptionsValueType.new,
  experienced: ExperienceLevelOptionsValueType.experienced,
  an_expert: ExperienceLevelOptionsValueType.an_expert,
}

export function isElasticsearchServerless(resourceType: ResourceType, useCase: string | undefined) {
  return resourceType === ResourceType.Serverless && useCase === 'search'
}

export function toDefaultSolutionView(
  useCase: UseCaseOptionsType | undefined,
): DefaultSolutionView | undefined {
  switch (useCase) {
    case UseCaseOptionsType.search:
      return 'search'
    case UseCaseOptionsType.observability:
      return 'observability'
    case UseCaseOptionsType.security:
      return 'security'
    default:
      return undefined
  }
}

export const isWithinLast4Hours = (startDate: Date | number): boolean => {
  // Get the current date-time
  const now = new Date()

  // Calculate the difference in milliseconds
  const diffInMs = now.getTime() - (typeof startDate === 'number' ? startDate : startDate.getTime())

  // Check if the difference is within 12 hours
  return diffInMs <= ONBOARDING_TOKEN_VALIDITY_WINDOW && diffInMs >= 0
}

export function isUseCaseDetailWithMigrationFromSiem(
  useCaseDetail: AnyUseCaseDetail | undefined,
): useCaseDetail is UseCaseDetailWithMigrationFromSiem {
  if (!useCaseDetail) {
    return false
  }

  if (useCaseDetail.security.use_case === SECURITY_USE_CASES_SIEM) {
    return true
  }

  return false
}
