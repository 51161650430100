/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiPanel,
  EuiSpacer,
} from '@elastic/eui'

import type { SaasUserResponse } from '@modules/cloud-api/v1/types'
import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-user-lib/hooks/list'
import { useListDeploymentsQuery } from '@modules/cloud-lib/deployments/hooks'
import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'

// eslint-disable-next-line import/no-restricted-paths
import { isMarketplaceSubscription } from '@/lib/marketPlace'

import Instances from './Instances'
import Header from './Header'
import Subscribe from './Subscribe'
import Pricing from './Pricing'
import ExtendTrial from './ExtendTrial'
import Help from './Help'
import Costs from './Costs'

type Props = {
  saasUser: SaasUserResponse
  onClose: () => void
}

/**
 * This name is a temporary misnomer. It will be the unified trial summary modal
 * when the deployment statistics are added and the logic to decide which to
 * display will be internal to this component.
 */
const UnifiedTrialSummaryModal: React.FunctionComponent<Props> = ({ saasUser, onClose }) => {
  const deploymentQuery = useListDeploymentsQuery({ staleTime: 5 * 60 * 1000 })

  const elasticsearchQuery = useListElasticsearchProjectsQuery()
  const observabilityQuery = useListObservabilityProjectsQuery()
  const securityQuery = useListSecurityProjectsQuery()

  const deployment = deploymentQuery.data?.deployments[0]

  const project =
    elasticsearchQuery.data?.items[0] ||
    observabilityQuery.data?.items[0] ||
    securityQuery.data?.items[0]

  const isMarketplace = isMarketplaceSubscription(saasUser.user.domain)
  const isGovCloud = Boolean(useConfig(Feature.hideIrrelevantSectionsFromGovCloud))
  const isCCFlowAvailable = !isGovCloud && !isMarketplace

  return (
    <EuiModal onClose={onClose} maxWidth='64rem' data-test-id='trial-summary-modal'>
      <EuiModalHeader>
        <Header saasUser={saasUser} />
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFlexGroup alignItems='stretch'>
          <EuiFlexItem grow={2}>
            <EuiPanel color='subdued' hasShadow={false}>
              <EuiFlexGroup
                direction='column'
                justifyContent='spaceBetween'
                alignItems='stretch'
                style={{ height: '100%' }}
              >
                <EuiFlexItem grow={true}>
                  <Costs saasUser={saasUser} currentProject={project} />
                </EuiFlexItem>
                {isCCFlowAvailable && (
                  <EuiFlexItem grow={false}>
                    <EuiSpacer size='xs' />
                    <Subscribe />
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiPanel color='transparent'>
              <Instances deployment={deployment} project={project} />
              {(deployment || project) && <EuiSpacer size='l' />}
              <Pricing closeModal={onClose} />
              <EuiSpacer size='l' />
              <Help />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='l' />
        <ExtendTrial saasUser={saasUser} />
      </EuiModalBody>
    </EuiModal>
  )
}

export default UnifiedTrialSummaryModal
