/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'

import { useGetEntitlementsV1 } from '@modules/billing-lib/hooks'
import type { UserProfile } from '@modules/ui-types'

import ExtendTrialModal from './ExtendTrialModal'
import ExtendTrialButton from './ExtendTrialButton'

interface Props {
  profile: UserProfile
  onTrialExtensionSuccess: () => void
}

const ExtendTrialSection: React.FunctionComponent<Props> = ({
  profile,
  onTrialExtensionSuccess,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const entitlementsQuery = useGetEntitlementsV1(profile.organization_id, {
    enabled: Boolean(profile.organization_id),
  })

  return (
    <Fragment>
      <ExtendTrialButton
        isTrialExtendable={entitlementsQuery.data?.trial_extendable ?? false}
        onTrialExtended={() => onTrialExtensionSuccess()}
      />

      {isModalOpen && (
        <ExtendTrialModal
          onClose={() => setIsModalOpen(false)}
          onTrialExtensionSuccess={onTrialExtensionSuccess}
        />
      )}
    </Fragment>
  )
}

export default ExtendTrialSection
