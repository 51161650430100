/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  MIGRATION_FROM_SIEM_NO,
  SECURITY_USE_CASES_SIEM,
} from '@modules/discovery-questions-lib/constants'
import type { DiscoveryQuestionsStateType } from '@modules/discovery-questions-lib/hooks'
import type { UpdateDiscoveryQuestions } from '@modules/discovery-questions-lib/steps'
import type {
  AnyUseCaseDetail,
  MigrationFromSiem,
  MigrationFromSiemOption,
  UseCaseDetailWithMigrationFromSiem,
} from '@modules/discovery-questions-lib/types'

/**
 * Transforms the update discovery questions to the discovery questions state type without using type assertions.
 * Since we have a more lax data coming from the API, we need to adapt it to the strict state type.
 */
export function fromUpdateDiscoveryQuestionsToDiscoveryQuestionsStateTypeAdapter(
  discoveryQuestions: Partial<UpdateDiscoveryQuestions> | undefined,
): Partial<DiscoveryQuestionsStateType> | undefined {
  if (discoveryQuestions === undefined) {
    return undefined
  }

  if ('use_case_detail' in discoveryQuestions) {
    return {
      ...discoveryQuestions,
      use_case_detail: fromRawUseCaseDetailToAnyUseCaseDetailsAdapter(
        discoveryQuestions.use_case_detail,
      ),
    }
  }

  return {
    ...discoveryQuestions,
    use_case_detail: undefined,
  }
}

/**
 * Transforms the raw use case detail coming from the API, which is a string, to a more strict type.
 * @param use_case_detail the raw use case detail coming from the API
 * @returns the use case detail object corresponding to the strict type
 */
export function fromRawUseCaseDetailToAnyUseCaseDetailsAdapter(
  use_case_detail: string | undefined,
): AnyUseCaseDetail | undefined {
  if (use_case_detail === undefined) {
    return undefined
  }

  try {
    return JSON.parse(use_case_detail) as AnyUseCaseDetail
  } catch {
    return undefined
  }
}

/**
 * Transforms the migration from SIEM option from a union to the appropriate migration from SIEM object to be sent as
 * the payload for EBT.
 * @param value the migration from SIEM option
 * @returns the migration from SIEM object
 */
export function fromMigrationFromSiemOptionToMigrationFromSiem(
  value: MigrationFromSiemOption,
): MigrationFromSiem {
  if (value === MIGRATION_FROM_SIEM_NO) {
    return { migration_from_siem: false }
  }

  return {
    migration_from_siem: true,
    migration_from_siem_origin: value,
  }
}

/**
 * Transforms the use case detail object when the user confirms he/she is migrating from SIEM to one of the simpler
 * migration from SIEM option or `undefined`. The use case detail object is a part of the discovery
 * questions state while the migration from SIEM option is used as the union of the options the user actually sees in
 * the screen.
 * @param value the object representing the use case detail
 * @returns the migration from SIEM option or `undefined`
 */
export function fromUseCaseDetailWithMigrationFromSiemToMigrationFromSiemOption(
  value: UseCaseDetailWithMigrationFromSiem,
): MigrationFromSiemOption | undefined {
  if (value.security.migration === undefined) {
    return undefined
  }

  if (value.security.migration.value === false) {
    return MIGRATION_FROM_SIEM_NO
  }

  return value.security.migration.type
}

/**
 * Transforms the migration from SIEM option from a union to the appropriate object which is part of the discovery
 * questions state.
 * @param value the migration from SIEM option
 * @returns the object representing the use case detail to be stored in the discovery questions state
 */
export function fromMigrationFromSiemOptionToDiscoveryQuestionsStateType(
  value: MigrationFromSiemOption,
): Partial<DiscoveryQuestionsStateType> {
  if (value === MIGRATION_FROM_SIEM_NO) {
    return {
      use_case_detail: {
        security: {
          use_case: SECURITY_USE_CASES_SIEM,
          migration: {
            value: false,
          },
        },
      },
    }
  }

  return {
    use_case_detail: {
      security: {
        use_case: SECURITY_USE_CASES_SIEM,
        migration: {
          value: true,
          type: value,
        },
      },
    },
  }
}
