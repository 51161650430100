/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { DefaultSolutionView } from '@modules/deployment-creation-wizard/types'
import useEbt from '@modules/ebt/hook'
import type { SnakeCaseToPascalCase } from '@modules/ebt/types'

const DEPLOYMENT_USE_CASE = 'deployment_use_case' as const
const DEPLOYMENT_RESOURCE = 'deployment_resource' as const
const DEPLOYMENT_KIBANA = 'deployment_kibana' as const

const EVENT_TYPES = [DEPLOYMENT_USE_CASE, DEPLOYMENT_RESOURCE, DEPLOYMENT_KIBANA] as const

type EventType = (typeof EVENT_TYPES)[number]

type Answer<T extends EventType> = T extends typeof DEPLOYMENT_USE_CASE
  ? DefaultSolutionView
  : T extends typeof DEPLOYMENT_RESOURCE
  ? {
      region: string
      version: string
      platform: string
      hardware: string
    }
  : T extends typeof DEPLOYMENT_KIBANA
  ? boolean
  : never

interface DeploymentCreationWizardEventSchema<T extends EventType> {
  answer: Answer<T>
  resource: 'stateful'
}

const useEbtDeploymentCreationWizard = (): Record<
  `report${SnakeCaseToPascalCase<EventType>}Ebt`,
  (arg: DeploymentCreationWizardEventSchema<EventType>['answer']) => void
> => {
  const reportEbtEvent = useEbt<EventType, DeploymentCreationWizardEventSchema<EventType>>(
    {
      eventType: DEPLOYMENT_USE_CASE,
      schemaProps: {
        answer: {
          description: 'The default solution view chosen by the user before creating a deployment',
        },
        resource: {
          description: 'The type of resource the event is related to (stateful)',
        },
      },
    },
    {
      eventType: DEPLOYMENT_RESOURCE,
      schemaProps: {
        answer: {
          description: 'The selected cloud provider, region, hardware, and version',
        },
        resource: {
          description: 'The type of resource the event is related to (stateful)',
        },
      },
    },
    {
      eventType: DEPLOYMENT_KIBANA,
      schemaProps: {
        answer: {
          description: 'Whether the user opened Kibana during the deployment creation process',
        },
        resource: {
          description: 'The type of resource the event is related to (stateful)',
        },
      },
    },
  )

  const resource = 'stateful'

  return {
    reportDeploymentUseCaseEbt: (useCase: DefaultSolutionView) => {
      reportEbtEvent(DEPLOYMENT_USE_CASE, {
        answer: useCase,
        resource,
      })
    },
    reportDeploymentResourceEbt: (config: Answer<typeof DEPLOYMENT_RESOURCE>) => {
      reportEbtEvent(DEPLOYMENT_RESOURCE, {
        answer: config,
        resource,
      })
    },
    reportDeploymentKibanaEbt: () => {
      reportEbtEvent(DEPLOYMENT_KIBANA, {
        answer: true,
        resource,
      })
    },
  }
}

export default useEbtDeploymentCreationWizard
