/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { useGetMinimalVersion } from '@modules/autoops-lib/hooks/useGetMinimalVersion'
import { type StackDeployment } from '@modules/ui-types'

// eslint-disable-next-line import/no-restricted-paths
import { gte } from '@/lib/semver'
// eslint-disable-next-line import/no-restricted-paths
import { getVersion } from '@/lib/stackDeployments/selectors/fundamentals'

export const useGetVersionCheckStatus = (
  deployment: StackDeployment,
): 'loading' | 'enabled' | 'disabled' => {
  const [isFlagsUsable, flags] = useFlagsWhenLoaded()
  const { data, status } = useGetMinimalVersion(isFlagsUsable && !!flags.autoopsVersionCheck)

  if (!isFlagsUsable && status === 'idle') {
    return 'loading'
  }

  if (isFlagsUsable && status === 'idle') {
    return 'enabled'
  }

  if (status === 'loading') {
    return 'loading'
  }

  if (status === 'error' || status !== 'success') {
    return 'enabled'
  }

  const deploymentVersion = getVersion({ deployment })

  try {
    if (deploymentVersion) {
      return gte(deploymentVersion, data.version) ? 'enabled' : 'disabled'
    }

    return 'enabled'
  } catch (error) {
    return 'enabled'
  }
}
