/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiSpacer, EuiTitle } from '@elastic/eui'

import Breadcrumbs from '@modules/cui/Breadcrumbs/Breadcrumbs'
import { solutionCreationCrumbs } from '@modules/project-creation-lib/breadcrumbs'
import { useSolutionOptions } from '@modules/solution-selector/hooks'
import history from '@modules/utils/history'
import { projectTypeCreationUrl } from '@modules/project-creation-lib/urls'

import ProjectCreationContainer from '../ProjectCreationContainer'

import SelectProjectTypeCard from './SelectProjectTypeCard'

import type { FunctionComponent } from 'react'

const SelectSolution: FunctionComponent = () => {
  const solutionOptions = useSolutionOptions()

  return (
    <Fragment>
      <Breadcrumbs breadcrumbs={solutionCreationCrumbs()} />
      <ProjectCreationContainer
        title={
          <EuiTitle size='m'>
            <h1>
              <FormattedMessage
                id='select-solution.main-title-b'
                defaultMessage='Which type of project would you like to create?'
              />
            </h1>
          </EuiTitle>
        }
        step={1}
      >
        <EuiFlexGroup direction='column' alignItems='center'>
          <EuiSpacer size='s' />
          <EuiFlexGroup alignItems='stretch' justifyContent='center'>
            {solutionOptions.map(({ label, description, features, logo, title }, index) => (
              <SelectProjectTypeCard
                label={label}
                description={description}
                features={features}
                logo={logo}
                title={title}
                key={`${index}-key`}
                onNext={() => history.push(projectTypeCreationUrl(label))}
              />
            ))}
          </EuiFlexGroup>
        </EuiFlexGroup>
      </ProjectCreationContainer>
    </Fragment>
  )
}

export default SelectSolution
