/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiLink } from '@elastic/eui'

import type { SaasAuthMfaDeviceResponse, SaasAuthResponse } from '@modules/cloud-api/v1/types'
import { filterWeakMfaDevices } from '@modules/mfa-lib/devices'
import type { MfaChallenge } from '@modules/cloud-lib/users/hooks/mfa'
import {
  useDeleteSmsDevicesMutation,
  useVerifySaasCurrentUserMfaChallengeMutation,
} from '@modules/cloud-lib/users/hooks/mfa'

import { getPreferredMfaDevice, setLastUsedMfaDeviceType } from './lib'
import AuthenticatorForm from './AuthenticatorForm'
import EmailForm from './EmailForm'
import SmsForm from './SmsForm'
import SelectDevice from './SelectDevice'
import WebAuthnForm from './WebAuthnForm'

const MfaForm: React.FunctionComponent<{
  stateId: string
  devices: SaasAuthMfaDeviceResponse[]
  isFlowV2: boolean | undefined
  onSuccess: (data: SaasAuthResponse) => void
}> = ({ stateId, devices, isFlowV2, onSuccess }) => {
  const activeDevices = filterWeakMfaDevices({ devices })

  const [device, setDevice] = useState<SaasAuthMfaDeviceResponse | null>(
    getPreferredMfaDevice(activeDevices),
  )

  const verifyMutation = useVerifySaasCurrentUserMfaChallengeMutation()
  const { reset } = verifyMutation

  const deleteSmsDevicesMutation = useDeleteSmsDevicesMutation()

  useEffect(() => {
    reset() // on device change

    return () => {
      reset() // on unmount
    }
  }, [reset, device])

  const onSubmit = (challenge: MfaChallenge) => {
    if (!device) {
      return // sanity
    }

    verifyMutation.mutate(
      { deviceId: device.device_id, stateId, challenge },
      {
        onSuccess: (data) => {
          setLastUsedMfaDeviceType(device.device_type)

          // Deleting SMS devices after successful authentication,
          // as they are considered deprecated and soon to be unsupported.
          deleteSmsDevicesMutation.mutate({ devices })

          onSuccess(data)
        },
      },
    )
  }

  return (
    <div
      css={css([
        isFlowV2 && {
          width: 600,
          marginLeft: -140,
        },
      ])}
    >
      <EuiFlexGroup direction='column' gutterSize='xl' alignItems='center'>
        <EuiFlexItem>
          {!device && <SelectDevice devices={activeDevices} onSelect={setDevice} />}

          {device?.device_type === 'GOOGLE' && (
            <AuthenticatorForm verifyMutation={verifyMutation} onSubmit={onSubmit} />
          )}
          {device?.device_type === 'EMAIL' && (
            <EmailForm
              device={device}
              stateId={stateId}
              verifyMutation={verifyMutation}
              onSubmit={onSubmit}
            />
          )}
          {device?.device_type === 'SMS' && (
            <SmsForm
              device={device}
              stateId={stateId}
              verifyMutation={verifyMutation}
              onSubmit={onSubmit}
            />
          )}
          {device?.device_type === 'WEBAUTHN' && (
            <WebAuthnForm
              device={device}
              stateId={stateId}
              verifyMutation={verifyMutation}
              onSubmit={onSubmit}
            />
          )}
        </EuiFlexItem>
        {device && activeDevices.length > 1 && (
          <EuiFlexItem>
            <EuiLink onClick={() => setDevice(null)}>
              <FormattedMessage
                id='mfaLogin.changeDevice'
                defaultMessage='Authenticate with a different method'
              />
            </EuiLink>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </div>
  )
}

export default MfaForm
