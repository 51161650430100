/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import { capitalize } from 'lodash'

import { type DiscoveryQuestionsStateType } from '../../discovery-questions-lib/hooks'
import { useCreateProject } from '../../project-creation-lib/hooks'
import genericMessages from '../../project-lib/genericMessages'
import { isElasticsearchServerless, type ResourceType } from '../../discovery-questions-lib/utils'
import { projectTypeOnboardingStartingUrl } from '../../project-creation-lib/urls'
import { getProjectTypeFromUseCase } from '../helpers'

import type React from 'react'
import type { ProjectApiError } from '../../ui-types/projects'
import type { ElasticsearchOptimizedFor } from '../../project-admin-api/v1/types'
import type { OnboardingToken } from '../../discovery-questions-lib/steps'
import type { Region } from '../../cluster-user-api/v1/types'

interface UseCreateProject {
  launchProjectCreation: (region?: Region) => void
}

interface UseCreateProjectProps {
  answers: Partial<DiscoveryQuestionsStateType>
  resourceType: ResourceType
  setErrors: React.Dispatch<React.SetStateAction<React.ReactNode[]>>
  setResourceCreationLaunched: (resourceCreationLaunched: boolean) => void
  onboardingToken?: OnboardingToken
  projectSubtype?: ElasticsearchOptimizedFor
}

export const useCreateOnboardingProject = ({
  answers,
  resourceType,
  setErrors,
  setResourceCreationLaunched,
  onboardingToken,
  projectSubtype,
}: UseCreateProjectProps): UseCreateProject => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { createProject } = useCreateProject()

  const { use_case: useCase } = answers
  const projectType = getProjectTypeFromUseCase(useCase)
  const projectName = formatMessage(genericMessages.defaultProjectName, {
    label: capitalize(projectType),
  })

  const launchProjectCreation = useCallback(
    (region: Region | undefined) => {
      if (
        region === undefined ||
        (isElasticsearchServerless(resourceType, useCase) && projectSubtype === undefined)
      ) {
        return
      }

      createProject({
        projectName,
        region: region.id as string,
        label: projectType ?? 'elasticsearch',
        optimizedFor: projectSubtype,
        onSuccess: ({ type: successProjectType, id: projectId }) => {
          history.push(
            projectTypeOnboardingStartingUrl(successProjectType, projectId, onboardingToken),
          )
        },
        onError: (projectApiError: ProjectApiError) => {
          setErrors(
            typeof projectApiError === 'string'
              ? [projectApiError]
              : projectApiError.errors.map(({ message }) => message),
          )
          setResourceCreationLaunched(false)
        },
      })
    },
    [
      createProject,
      history,
      onboardingToken,
      projectName,
      projectSubtype,
      projectType,
      resourceType,
      setErrors,
      setResourceCreationLaunched,
      useCase,
    ],
  )

  return {
    launchProjectCreation,
  }
}
