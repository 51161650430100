/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiFlexItem, EuiFlexGroup } from '@elastic/eui'

import type { StackDeployment } from '@modules/ui-types'
import { withSmallErrorBoundary } from '@modules/cui/Boundary'
import { getKibanaRedirectPageUrl } from '@modules/kibana-redirect/url'
import { AutoOpsButton } from '@modules/auto-ops-components/AutoOpsButton/AutoOpsButton'
import { useConfig } from '@modules/cui/ConfigContext'

import { isDeploymentAvailableInKibana } from '@/lib/stackDeployments/selectors/available'
import { getDeploymentResourceEndpoint } from '@/lib/stackDeployments/selectors/endpoints'

import { useOnboardingTokenFromProfile } from '../../../../modules/discovery-questions-lib/hooks'

import StackDeploymentAdminActionsDropdown from './StackDeploymentAdminActionsDropdown'

import type { FunctionComponent } from 'react'

type Props = {
  deployment: StackDeployment
  hideClusterInsteadOfDelete: boolean
  hideClusterInsteadOfStop: boolean
}

const StackDeploymentAdminActions: FunctionComponent<Props> = ({
  deployment,
  hideClusterInsteadOfDelete,
  hideClusterInsteadOfStop,
}) => (
  <EuiFlexGroup
    gutterSize='m'
    responsive={false}
    className='deploymentOverview-managementControls fs-unmask'
  >
    <EuiFlexItem grow={false}>
      <AutoOpsButton deployment={deployment} />
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <LaunchKibanaButton deployment={deployment} />
    </EuiFlexItem>

    <EuiFlexItem grow={false}>
      <StackDeploymentAdminActionsDropdown
        deployment={deployment}
        hideClusterInsteadOfDelete={hideClusterInsteadOfDelete}
        hideClusterInsteadOfStop={hideClusterInsteadOfStop}
      />
    </EuiFlexItem>
  </EuiFlexGroup>
)

const LaunchKibanaButton = ({ deployment }: { deployment: StackDeployment }) => {
  const {
    resources: { kibana },
  } = deployment

  const onboardingToken = useOnboardingTokenFromProfile()

  const isDeploymentAvailable = isDeploymentAvailableInKibana(deployment)

  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'

  if (!kibana || kibana.length === 0) {
    return null
  }

  const href = isAdminConsole
    ? getDeploymentResourceEndpoint({ deployment, sliderInstanceType: `kibana` })
    : getKibanaRedirectPageUrl(`deployment`, deployment.id, onboardingToken)

  return (
    <EuiButton
      href={href}
      isDisabled={!isDeploymentAvailable}
      data-test-id='deployment-launch-kibana'
    >
      <FormattedMessage id='deployment-admin-actions.kibana' defaultMessage='Open Kibana' />
    </EuiButton>
  )
}

export default withSmallErrorBoundary(StackDeploymentAdminActions)
