/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Fragment, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'

import {
  EuiButton,
  EuiButtonGroup,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiSkeletonText,
  EuiPanel,
  EuiRadioGroup,
  EuiSpacer,
  EuiText,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'

import history from '@modules/utils/history'
import {
  experienceLevelOptions,
  trialIntentOptions,
  useCaseOptions,
} from '@modules/discovery-questions-pages/DiscoveryQuestionsV0/options'
import { invalidateGetUserProfileQuery, useProfile } from '@modules/profile-lib/hooks'
import type { DiscoveryQuestionsPayload } from '@modules/discovery-questions-lib/hooks'
import {
  useOnboardingToken,
  usePutDiscoveryQuestionsMutation,
} from '@modules/discovery-questions-lib/hooks'
import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { postCloudAnalytics } from '@modules/analytics-api/caller'
import {
  ResourceType,
  type ExperienceLevelOptionsType,
  type ExperienceLevelOptionsValueType,
  type TrialIntentOptionsType,
  type TrialIntentOptionsValueType,
  type UseCaseOptionsType,
  type UseCaseOptionsValueType,
} from '@modules/discovery-questions-lib/utils'

// eslint-disable-next-line import/no-restricted-paths
import { isGCPUser } from '@/lib/marketPlace'
// eslint-disable-next-line import/no-restricted-paths
import { createDeploymentUrl, marketplaceConversionUrl } from '@/lib/urlBuilder'

import { convertOnboardingToken } from '../../onboarding-combine-flow/helpers'

import {
  experienceLevelOptionLabels,
  messages,
  trialIntentOptionLabels,
  useCaseOptionLabels,
} from './messages'

import type { ChangeEvent, FunctionComponent } from 'react'
import type { AllProps as Props } from './types'

type OptionsTypeConversion<T> = T extends ExperienceLevelOptionsType
  ? ExperienceLevelOptionsValueType
  : T extends TrialIntentOptionsType
  ? TrialIntentOptionsValueType
  : T extends UseCaseOptionsType
  ? UseCaseOptionsValueType
  : ''
interface FormFieldOptionProps<
  T = ExperienceLevelOptionsType | TrialIntentOptionsType | UseCaseOptionsType,
> {
  id: T
  label: string
  value: OptionsTypeConversion<T>
}

interface FormErrors {
  fullName: string | null
  company: string | null
  experience_level: string | null
  trial_intent: string | null
  use_case: string | null
  use_case_detail: string | null
}

interface DiscoveryFormState {
  full_name: string
  company: string
  experience_level: FormFieldOptionProps<ExperienceLevelOptionsType>
  trial_intent: FormFieldOptionProps<TrialIntentOptionsType>
  use_case: FormFieldOptionProps<UseCaseOptionsType>
  use_case_detail: string
}

const DiscoveryQuestions: FunctionComponent<Props> = ({ intl: { formatMessage }, children }) => {
  const noSelectedOption: FormFieldOptionProps<any> = { id: '', label: '', value: '' }
  const onboardingToken = useOnboardingToken()
  const [isFlagUsable, flags] = useFlagsWhenLoaded()

  const [discoveryForm, setDiscoveryForm] = useState<DiscoveryFormState>({
    full_name: '',
    company: '',
    experience_level: noSelectedOption,
    trial_intent: noSelectedOption,
    use_case: noSelectedOption,
    use_case_detail: '',
  })
  const [errors, setErrors] = useState<FormErrors>({
    fullName: null,
    company: null,
    experience_level: null,
    trial_intent: null,
    use_case: null,
    use_case_detail: null,
  })
  const { pathname, search } = useLocation()
  const query = parse(search.slice(1))

  const isMarketplaceConversionUrl = pathname === marketplaceConversionUrl()
  const isCreateDeploymentUrl = pathname === createDeploymentUrl()
  const isServerlessDiscoveryQuestions = pathname.startsWith('/onboarding')

  const { mutate, isLoading, isSuccess } = usePutDiscoveryQuestionsMutation()
  const putDiscoveryQuestions = (discoveryForm) =>
    mutate(
      { body: discoveryForm },
      {
        onSuccess: () => postCloudAnalytics({ userId: profile?.user_id, questions: discoveryForm }),
      },
    )

  useEffect(() => {
    if (isSuccess) {
      invalidateGetUserProfileQuery()
    }
  }, [isSuccess])

  const profile = useProfile()

  useEffect(() => {
    if (!isMarketplaceConversionUrl && !isCreateDeploymentUrl) {
      history.push(createDeploymentUrl())
    }
  }, [profile?.data.discovery])

  if (!profile) {
    return <EuiSkeletonText />
  }

  const {
    data: { company_name: companyNameFromProfile },
  } = profile

  const fullNameFromProfile = getFullNameFromProfile()
  const experienceLevelError = errors.experience_level
  const useCaseError = errors.use_case || errors.use_case_detail
  const trialIntentError = errors.trial_intent

  function getFullNameFromProfile() {
    if (!profile) {
      return
    }

    const {
      data: { first_name, last_name },
    } = profile

    if (first_name && last_name) {
      return `${first_name} ${last_name}`
    }

    if (first_name) {
      return first_name
    }

    if (last_name) {
      return last_name
    }

    return ''
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target
    updateFormState(name, value)
  }

  function onSelectExperienceLevel(selectedId: string) {
    const options = getExperienceLevelOptions()
    const selectedOption = options.find((option) => selectedId === option.id)
    updateFormState('experience_level', selectedOption!)
  }

  function onSelectUseCase(selectedId: string) {
    const options = getUseCaseOptions()
    const selectedOption = options.find((option) => selectedId === option.id)
    updateFormState('use_case', selectedOption!)
  }

  function onSelectTrialIntent(selectedId: string) {
    const options = getTrialIntentOptions()
    const selectedOption = options.find((option) => selectedId === option.id)
    updateFormState('trial_intent', selectedOption!)
  }

  function updateFormState(name: string, selectedOption: FormFieldOptionProps | string) {
    setDiscoveryForm({
      ...discoveryForm,
      [name]: selectedOption,
    })
  }

  function onSubmit() {
    const data = profile?.data
    const isValid = validateForm()

    if (!isValid) {
      return
    }

    const { company, experience_level, full_name, trial_intent, use_case, use_case_detail } =
      discoveryForm

    const payload: DiscoveryQuestionsPayload = {
      company: data?.company_name || company.trim(),
      full_name: getFullNameFromProfile() || full_name.trim(),
      experience_level: experience_level.value,
      trial_intent: trial_intent.value,
      use_case: use_case.value,
      onboarding_token: onboardingToken,
    }

    if (use_case.id === 'something_else') {
      payload.use_case_detail = use_case_detail
    }

    putDiscoveryQuestions(payload)
  }

  function validateForm() {
    const data = profile?.data

    const { company, full_name, experience_level, trial_intent, use_case, use_case_detail } =
      discoveryForm
    const errors: FormErrors = {
      fullName: null,
      company: null,
      experience_level: null,
      trial_intent: null,
      use_case: null,
      use_case_detail: null,
    }
    const fieldIsRequiredMessage = formatMessage(messages.requireField)
    const requiredUseCaseDetailFieldMessage = formatMessage(
      messages.requiredUseCaseDetailFieldMessage,
    )
    const fullName = full_name.trim()
    const companyName = company.trim()

    if (!fullName && !getFullNameFromProfile()) {
      errors.fullName = fieldIsRequiredMessage
    }

    if (!companyName && !data?.company_name) {
      errors.company = fieldIsRequiredMessage
    }

    if (!experience_level.id) {
      errors.experience_level = fieldIsRequiredMessage
    }

    if (!trial_intent.id) {
      errors.trial_intent = fieldIsRequiredMessage
    }

    if (!use_case.id) {
      errors.use_case = fieldIsRequiredMessage
    }

    if (use_case.id === 'something_else' && !use_case_detail) {
      errors.use_case_detail = requiredUseCaseDetailFieldMessage
    }

    setErrors({ ...errors })

    return Object.values(errors).every((error) => !error)
  }

  function getExperienceLevelOptions(): FormFieldOptionProps[] {
    return experienceLevelOptions.map(({ id, value }) => ({
      id,
      label: formatMessage(experienceLevelOptionLabels[id]),
      value,
    }))
  }

  function getUseCaseOptions(): FormFieldOptionProps[] {
    return useCaseOptions.map(({ id, value }) => ({
      id,
      label: formatMessage(useCaseOptionLabels[id]),
      value,
    }))
  }

  function getTrialIntentOptions(): FormFieldOptionProps[] {
    return trialIntentOptions.map(({ id, value }) => ({
      id,
      label: formatMessage(trialIntentOptionLabels[id]),
      value,
    }))
  }

  function showDiscoveryQuestions() {
    const registrationSource = profile?.data.registration_source

    return (
      !isMarketplaceConversionUrl &&
      !isServerlessDiscoveryQuestions &&
      !(registrationSource === 'community') &&
      !(registrationSource === 'training') &&
      !(registrationSource === 'support') &&
      !(registrationSource === 'organization-invite') &&
      !query.invitationToken &&
      !query.onboarding_token
    )
  }

  if (profile.data.discovery || !showDiscoveryQuestions()) {
    return <Fragment>{children}</Fragment>
  }

  if (!isFlagUsable) {
    return <EuiSkeletonText />
  }

  function redirectOnboarding(token?: string) {
    if (flags.onboardingResource === ResourceType.Serverless) {
      history.push(`/onboarding/serverless${token ? `?onboarding_token=${token}` : ''}`)
      return null
    }

    history.push(`/onboarding/stateful${token ? `?onboarding_token=${token}` : ''}`)
    return null
  }

  if (flags.signUpFlowV2 && !profile.organization_id) {
    const queryOnboardingToken = query.onboarding_token

    if (queryOnboardingToken && !Array.isArray(queryOnboardingToken)) {
      const token = convertOnboardingToken(queryOnboardingToken)

      if (token && typeof token === 'string') {
        redirectOnboarding(token)
      }

      history.push(`/onboarding/stateful?onboarding_token=${token}`)
      return null
    }

    redirectOnboarding()
  }

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <LandingPageOuterContainer isFlowV2={false} pageContext={{ name: 'loggedIn' }}>
          <EuiCard
            css={css`
              margin: auto;
              width: 600px;
            `}
            title={
              <h2>
                <FormattedMessage
                  id='signup.discovery-questions.page-title'
                  defaultMessage='Welcome to Elastic'
                />
              </h2>
            }
          >
            <EuiSpacer size='m' />
            <EuiText size='m' textAlign='center' color='subdued'>
              <FormattedMessage
                id='signup.discovery-questions.page-subtitle'
                defaultMessage='Provide the information below for the best Elastic experience.'
              />
            </EuiText>
            <EuiSpacer size='m' />

            <EuiHorizontalRule margin='none' />

            <EuiSpacer size='l' />

            <EuiForm data-test-id='discovery-questions.form'>
              <EuiFormRow
                isInvalid={errors.fullName !== null}
                error={errors.fullName}
                fullWidth={true}
                label={
                  <FormattedMessage
                    id='signup.discovery-questions.full-name'
                    defaultMessage='Full name *'
                  />
                }
              >
                <EuiFieldText
                  name='full_name'
                  value={fullNameFromProfile || discoveryForm.full_name}
                  onChange={onChange}
                  fullWidth={true}
                  data-test-id='signup.discovery-questions.full-name'
                  disabled={
                    // @ts-ignore
                    isGCPUser(profile) && !!fullNameFromProfile && isLoading
                  }
                />
              </EuiFormRow>

              <EuiSpacer />

              <EuiFormRow
                isInvalid={errors.company !== null}
                error={errors.company}
                fullWidth={true}
                label={
                  <FormattedMessage
                    id='signup.discovery-questions.company-name'
                    defaultMessage='Company *'
                  />
                }
              >
                <EuiFieldText
                  name='company'
                  value={companyNameFromProfile || discoveryForm.company}
                  onChange={onChange}
                  fullWidth={true}
                  data-test-id='signup.discovery-questions.company-name'
                  disabled={
                    // @ts-ignore
                    isGCPUser(profile) && !!companyNameFromProfile
                  }
                />
              </EuiFormRow>

              <EuiSpacer />

              <EuiFormRow
                fullWidth={true}
                label={<FormattedMessage {...messages.experienceLabel} />}
                isInvalid={!!experienceLevelError}
                error={experienceLevelError}
              >
                <EuiButtonGroup
                  id='experience_level'
                  legend={formatMessage(messages.experienceLabel)}
                  options={getExperienceLevelOptions()}
                  idSelected={discoveryForm.experience_level.id}
                  onChange={onSelectExperienceLevel}
                  color='primary'
                  isFullWidth={true}
                  data-test-id='signup.discovery-questions.experience-level'
                  isDisabled={isLoading}
                />
              </EuiFormRow>

              <EuiSpacer />

              <EuiFormRow
                fullWidth={true}
                label={
                  <FormattedMessage
                    id='signup.discovery-questions.elastic-solutions'
                    defaultMessage='Which of the following are you primarily interested in? *'
                  />
                }
                isInvalid={!!useCaseError}
                error={useCaseError}
              >
                <EuiPanel
                  color='subdued'
                  css={css`
                    text-align: left;
                  `}
                >
                  <EuiRadioGroup
                    name='use_case'
                    options={getUseCaseOptions()}
                    idSelected={discoveryForm.use_case.id}
                    onChange={onSelectUseCase}
                    data-test-id='signup.discovery-questions.use-case'
                    disabled={false}
                  />

                  {discoveryForm.use_case.id === 'something_else' && (
                    <Fragment>
                      <EuiSpacer size='s' />

                      <EuiFieldText
                        placeholder={formatMessage(messages.useCaseDetailPlaceholder)}
                        name='use_case_detail'
                        value={discoveryForm.use_case_detail}
                        onChange={onChange}
                        fullWidth={true}
                        data-test-id='signup.discovery-questions.use-case-detail'
                      />
                    </Fragment>
                  )}
                </EuiPanel>
              </EuiFormRow>

              <EuiSpacer />

              <EuiFormRow
                fullWidth={true}
                label={<FormattedMessage {...messages.trialIntentLabel} />}
                isInvalid={!!trialIntentError}
                error={trialIntentError}
              >
                <EuiPanel
                  color='subdued'
                  css={css`
                    text-align: left;
                  `}
                >
                  <EuiRadioGroup
                    name='trial_intent'
                    options={getTrialIntentOptions()}
                    idSelected={discoveryForm.trial_intent.id}
                    onChange={onSelectTrialIntent}
                    data-test-id='signup.discovery-questions.trial-intent'
                    disabled={isLoading}
                  />
                </EuiPanel>
              </EuiFormRow>

              <EuiSpacer />

              <EuiFormRow fullWidth={true} style={{ textAlign: 'right' }}>
                <EuiButton
                  fill={true}
                  onClick={onSubmit}
                  style={{ width: '115px' }}
                  isLoading={isLoading || false}
                  data-test-id='signup.discovery-questions.submit'
                >
                  <FormattedMessage id='signup.discovery-questions.submit' defaultMessage='Next' />
                </EuiButton>
              </EuiFormRow>
            </EuiForm>
          </EuiCard>
        </LandingPageOuterContainer>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default injectIntl(DiscoveryQuestions)
