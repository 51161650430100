/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { ProjectType } from '@modules/ui-types/projects'
import type { ElasticsearchOptimizedFor } from '@modules/project-user-api/v1/types'

import type { MessageDescriptor } from 'react-intl'

const CLOUD_PROVIDER_IDS = ['aws', 'azure', 'gcp', 'local'] as const

const projectTypes: Record<ProjectType, MessageDescriptor> = defineMessages({
  elasticsearch: {
    id: 'projects.pretty.type.elasticsearch',
    defaultMessage: 'Elasticsearch',
  },
  observability: {
    id: 'projects.pretty.type.observability',
    defaultMessage: 'Observability',
  },
  security: {
    id: 'projects.pretty.type.security',
    defaultMessage: 'Security',
  },
})

const elasticsearchProjectSubtypes: Record<ElasticsearchOptimizedFor, MessageDescriptor> =
  defineMessages({
    general_purpose: {
      id: 'projects.pretty.subtype.elasticsearch-general',
      defaultMessage: 'General',
    },
    search: {
      id: 'projects.pretty.subtype.elasticsearch-search',
      defaultMessage: 'General',
    },
    vector: {
      id: 'projects.pretty.subtype.elasticsearch-vector',
      defaultMessage: 'Vector',
    },
  })

export function getPrettyProjectType(type: ProjectType): MessageDescriptor {
  return projectTypes[type]
}

export function getPrettyElasticsearchProjectSubtype(
  type: ElasticsearchOptimizedFor,
): MessageDescriptor {
  return elasticsearchProjectSubtypes[type]
}

export function getOnlyRegionId(regionId: string): string {
  const cloudProviderPrefixMatcher = new RegExp(`^(${CLOUD_PROVIDER_IDS.join('|')})-`)
  return regionId.replace(cloudProviderPrefixMatcher, '')
}

export function getDisplayProjectId(projectId: string) {
  return projectId.slice(0, 6)
}
