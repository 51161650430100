/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { ERROR_CHART_MESSAGE, NO_DATA_TITLE_BY_METRIC_TYPE } from '@modules/autoops-lib/constants'
import type { MetricItem, ChartType } from '@modules/autoops-api/types'

import type { MessageDescriptor } from 'react-intl'

// We're knowingly dividing by 1024^3 (i.e. GiB) because that's how usage is billed. However, this amount will be shown
// using the GB unit like everywhere else (Billing page, Kibana), even though it is not the right unit.
// This is mainly for consistency's sake.
export const bytesToGigabytes = (bytes: number): number => {
  const NUMBER_OF_BYTES_IN_A_GB = 1024 * 1024 * 1024

  return bytes / NUMBER_OF_BYTES_IN_A_GB
}

export const numberFormat = (value: number, options?: { decimals: number }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: options?.decimals ?? 2,
    maximumFractionDigits: options?.decimals ?? 2,
  })

  return formatter.format(value)
}

export const generateCallout = (
  chartType: ChartType,
  items: Array<MetricItem | undefined>,
): { type: 'info' | 'danger'; title: MessageDescriptor; message?: string } => {
  const definedItems = items.filter((item) => item !== undefined)
  const errorItems = definedItems.filter((item) => item.error !== null)

  if (items.length === errorItems.length) {
    return { type: 'danger', title: ERROR_CHART_MESSAGE, message: items[0]?.error ?? '' }
  }

  return { type: 'info', title: NO_DATA_TITLE_BY_METRIC_TYPE[chartType] }
}

export const calculateDomain = (item: MetricItem | undefined) => {
  if (!item || !item.data) {
    return { min: 0, max: 0 }
  }

  const { data } = item

  if (data.length === 0) {
    return { min: 0, max: 0 }
  }

  const values = data.map((unit) => unit[1])

  return { min: Math.min(...values), max: Math.max(...values) }
}

export const generateAutoOpsUrl = ({
  region,
  organizationId,
  deploymentId,
}: {
  region: string
  organizationId: string
  deploymentId: string
}) =>
  `https://app.auto-ops.${window.location.hostname}/regions/${region}/organizations/${organizationId}/deployments/${deploymentId}/deployment`
