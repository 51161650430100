/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, sortBy } from 'lodash'
import moment from 'moment'

import type {
  SaasOrganizationResponse,
  SaasUserResponse,
  SaasUserTrial,
} from '@modules/cloud-api/v1/types'
import type { OrganizationFromApi, UserProfileFromApi } from '@modules/ui-types'

export const DEFAULT_TRIAL_DAYS = 14
export const DEFAULT_TRIAL_MP_DAYS = 7
/**
 * Note about trials.
 * All users start off as trial users.
 * There is a `trials` property that is an array. A paying user or a new (non-expired) trial will have this array be
 * empty. However, an expired trial or one that has been extended will be populated with at least one object. This object
 * has the trial start/end dates and a type. The type seems to always be `elasticsearch`. So even though this is an array
 * we just find the first `elasticsearch` type trial and use the end date to confirm that they have either an expired
 * trial or have been extended.
 */
export function getTrialContext(userOrOrganization: UserProfileFromApi | OrganizationFromApi) {
  const { trials, trial_length = DEFAULT_TRIAL_DAYS } = userOrOrganization
  const inTrial = userOrOrganization.is_trial
  const currentTrial = getLatestTrial(userOrOrganization)
  const expired = hasExpiredTrial(userOrOrganization)
  const canRestartTrial = hasRestartableTrial(userOrOrganization)
  const trialDaysRemaining = calculateTrialDaysRemaining(userOrOrganization)

  return {
    trials,
    trial_length,
    inTrial,
    hasExpiredTrial: expired,
    canRestartTrial,
    currentTrial,
    trialDaysRemaining,
  }
}

function getLatestTrial({ trials }: { trials: SaasUserTrial[] }) {
  return sortBy(trials, (trial) => trial.end)[trials.length - 1] || undefined
}

function isTrialExpired(trial: SaasUserTrial): boolean {
  return moment().isAfter(trial.end)
}

function calculateTrialDaysRemaining(
  userOrOrganization: UserProfileFromApi | OrganizationFromApi,
): number | undefined {
  const expired = hasExpiredTrial(userOrOrganization)

  if (expired) {
    return userOrOrganization.is_trial ? 0 : undefined
  }

  const currentTrial = getLatestTrial(userOrOrganization)

  if (!currentTrial) {
    return
  }

  const end = moment(currentTrial.end)
  const date = moment().startOf('day')
  const duration = moment.duration(end.diff(date))
  return Math.floor(duration.asDays())
}

function hasExpiredTrial(userOrOrganization: UserProfileFromApi | OrganizationFromApi): boolean {
  if (!userOrOrganization.is_trial) {
    return false
  }

  const trials = userOrOrganization.trials.filter((trial) => trial.type === `elasticsearch`)

  if (isEmpty(trials)) {
    return false
  }

  // Users can have more than one trial, so we need to grab the most recent
  const currentTrial = getLatestTrial(userOrOrganization)
  return moment().isAfter(currentTrial?.end)
}

function hasRestartableTrial(
  userOrOrganization: UserProfileFromApi | OrganizationFromApi,
): boolean {
  if (userOrOrganization.trials.length < 1) {
    return false
  }

  const expired = hasExpiredTrial(userOrOrganization)
  const currentTrial = getLatestTrial(userOrOrganization)
  return expired && Boolean(currentTrial?.restartable)
}

export function doesSaasEntityHaveExpiredTrial(
  userOrOrg: SaasUserResponse | SaasOrganizationResponse,
): boolean {
  const latestTrial = getLatestTrial(userOrOrg)
  return latestTrial ? isTrialExpired(latestTrial) : false
}

export function getSaasEntityTrialDaysRemaining(
  userOrOrg: SaasUserResponse | SaasOrganizationResponse,
): number | undefined {
  const latestTrial = getLatestTrial(userOrOrg)

  if (!latestTrial) {
    return
  }

  if (isTrialExpired(latestTrial)) {
    return 0
  }

  const end = moment(latestTrial.end)
  const today = moment().startOf('day')
  const duration = moment.duration(end.diff(today))
  return Math.floor(duration.asDays())
}

export function getSaasLatestTrial(
  userOrOrg: SaasUserResponse | SaasOrganizationResponse,
): SaasUserTrial | undefined {
  return getLatestTrial(userOrOrg)
}
