/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { EuiLoadingSpinner, EuiPanel, useEuiTheme } from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'

import type { ReactNode } from 'react'

type ResultsProps<T> = {
  results?: {
    error?: Error
    isSearching: boolean
    record?: T[]
  }
  isBidingTime?: boolean
  position: number
  children: (record: T) => ReactNode
}

// eslint-disable-next-line comma-spacing
const Results = <T,>({ results, isBidingTime, children }: ResultsProps<T>) => {
  const { euiTheme } = useEuiTheme()

  if (results === undefined) {
    return null
  }

  const { error, isSearching, record } = results

  if (error) {
    return (
      <EuiPanel paddingSize='none' data-test-id='search-results-panel'>
        <div
          css={css`
            padding: ${euiTheme.size.base};
          `}
          data-test-id='noSearchResults'
        >
          <CuiAlert type='error'>{error}</CuiAlert>
        </div>
      </EuiPanel>
    )
  }

  if ((record === undefined && isSearching) || isBidingTime) {
    return (
      <EuiPanel paddingSize='none' data-test-id='search-results-panel'>
        <div
          css={css`
            padding: ${euiTheme.size.base};
          `}
          data-test-id='noSearchResults'
        >
          <EuiLoadingSpinner />
        </div>
      </EuiPanel>
    )
  }

  if (record === undefined || record.length === 0) {
    return (
      <EuiPanel paddingSize='none' data-test-id='search-results-panel'>
        <div
          css={css`
            padding: ${euiTheme.size.base};
          `}
          data-test-id='noSearchResults'
        >
          <CuiAlert type='warning'>
            <FormattedMessage
              id='typeahead-results.we-couldn-t-find-any-results-for-this-query'
              defaultMessage="We couldn't find any results for this query"
            />
          </CuiAlert>
        </div>
      </EuiPanel>
    )
  }

  return (
    <EuiPanel paddingSize='none' data-test-id='search-results-panel'>
      <ul
        css={{
          maxWidth: '26rem',
          position: 'absolute',
          zIndex: 9,
          boxShadow: '0 2px 2px rgba(0, 0, 0, 0.2)',
          marginBottom: '2em',
          padding: `${euiTheme.size.xs}`,
          overflow: 'hidden',
        }}
        data-test-id='hasSearchResults'
      >
        {record.map((item, index) => (
          <Result key={index} item={item} children={children} />
        ))}
      </ul>
    </EuiPanel>
  )
}

const Result = ({ item, children }) => {
  const { euiTheme } = useEuiTheme()

  const key = `${item.kind || item.type}-${item.id}`

  return (
    <li key={key} css={{ padding: `${euiTheme.size.xxs}` }}>
      {children(item)}
    </li>
  )
}

export default Results
