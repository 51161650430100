/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { sumBy, uniqBy } from 'lodash'
import { useContext, useState } from 'react'
import { useMutation } from 'react-query'

import type { CSP, Region } from '@modules/cluster-user-api/v1/types'
import { useConfig } from '@modules/cui/ConfigContext'
import ProfileContext from '@modules/profile-lib/ProfileContext'
import { useCreateOrganizationMutation } from '@modules/profile-lib/hooks'
import type { AnyProject } from '@modules/project-api/types'
import type { AnyUserProject } from '@modules/project-user-api/types'
import {
  createElasticsearchProject,
  createObservabilityProject,
  createSecurityProject,
} from '@modules/project-user-api/v1/callers'
import type {
  CreateElasticsearchProjectRequest,
  CreateObservabilityProjectRequest,
  CreateSecurityProjectRequest,
} from '@modules/project-user-api/v1/types'
import { makeQueryKey } from '@modules/project-user-lib/hooks/helpers'
import { queryClient } from '@modules/query'
import type { QueryFunctionReturnType } from '@modules/query/types'
import { type NonEmptyArray } from '@modules/ts-utils'
import type { ProjectApiError } from '@modules/ui-types/projects'

const useCreateElasticsearchProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof createElasticsearchProject>,
    ProjectApiError,
    { body: CreateElasticsearchProjectRequest }
  >({
    mutationFn: ({ body }) =>
      createElasticsearchProject({
        body,
      }),
    onSuccess: onProjectCreated,
  })

const useCreateObservabilityProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof createObservabilityProject>,
    ProjectApiError,
    { body: CreateObservabilityProjectRequest }
  >({
    mutationFn: ({ body }) =>
      createObservabilityProject({
        body,
      }),
    onSuccess: onProjectCreated,
  })

const useCreateSecurityProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof createSecurityProject>,
    ProjectApiError,
    { body: CreateSecurityProjectRequest }
  >({
    mutationFn: ({ body }) =>
      createSecurityProject({
        body,
      }),
    onSuccess: onProjectCreated,
  })

const onProjectCreated = (project: AnyUserProject) => {
  const listQueryKey = makeQueryKey(
    (
      {
        elasticsearch: 'listElasticsearchProjects',
        observability: 'listObservabilityProjects',
        security: 'listSecurityProjects',
      } as const
    )[project.type],
  )
  queryClient.invalidateQueries(listQueryKey)
}

type BaseCreateProjectPayload = {
  projectName: string
  region: string
  label: 'elasticsearch' | 'observability' | 'security'
  onSuccess: (project: AnyProject) => void
  onError?: (error: ProjectApiError) => void
}
type CreateElasticsearchProjectPayload = {
  label: 'elasticsearch'
  optimizedFor: CreateElasticsearchProjectRequest['optimized_for']
} & BaseCreateProjectPayload

type CreateObservabilityProjectPayload = {
  label: 'observability'
} & BaseCreateProjectPayload

type CreateSecurityProjectPayload = {
  label: 'security'
} & BaseCreateProjectPayload

type CreateProjectPayload =
  | CreateElasticsearchProjectPayload
  | CreateObservabilityProjectPayload
  | CreateSecurityProjectPayload

export const useCreateProject = () => {
  const profileQuery = useContext(ProfileContext)
  const userHasOrg = profileQuery.isReady && profileQuery.user?.organization_id

  const createOrganizationMutation = useCreateOrganizationMutation({})
  const createElasticsearchProjectMutation = useCreateElasticsearchProjectMutation()
  const createObservabilityProjectMutation = useCreateObservabilityProjectMutation()
  const createSecurityProjectMutation = useCreateSecurityProjectMutation()

  const projectMutations = [
    createElasticsearchProjectMutation,
    createObservabilityProjectMutation,
    createSecurityProjectMutation,
  ]

  const isLoading =
    createOrganizationMutation.isLoading || projectMutations.some((mutation) => mutation.isLoading)
  const isError =
    createOrganizationMutation.isError || projectMutations.some((mutation) => mutation.isError)

  const projectError = projectMutations.find((mutation) => mutation.error)?.error
  const organizationError = createOrganizationMutation.error

  return {
    createProject: userHasOrg ? createProject : createOrganizationAndProject,
    isLoading,
    isError,
    organizationError,
    projectError,
  }

  function createOrganizationAndProject(createProjectPayload: CreateProjectPayload) {
    createOrganizationMutation.mutate(undefined, {
      onSuccess: () => createProject(createProjectPayload),
    })
  }

  function createProject(createProjectPayload: CreateProjectPayload) {
    const { label, projectName, region, onSuccess, onError } = createProjectPayload

    const body = {
      name: projectName,
      region_id: region,
    }

    switch (label) {
      case 'elasticsearch':
        createElasticsearchProjectMutation.mutate(
          {
            body: {
              ...body,
              optimized_for: createProjectPayload.optimizedFor,
            },
          },
          {
            onSuccess,
            onError,
          },
        )
        break
      case 'observability':
        createObservabilityProjectMutation.mutate(
          {
            body,
          },
          {
            onSuccess,
            onError,
          },
        )
        break
      default:
        createSecurityProjectMutation.mutate(
          {
            body,
          },
          {
            onSuccess,
            onError,
          },
        )
        break
    }
  }
}

function useDefaultRegionChooser(regions: NonEmptyArray<Region>): Region {
  const weights = useConfig('DEFAULT_SERVERLESS_REGION_SELECTOR_WEIGHTS')

  if (weights) {
    return extractValidRegionWeightPairs(regions, weights)
  }

  return regions[0]
}

export function extractValidRegionWeightPairs(
  regions: NonEmptyArray<Region>,
  weights: Record<string, number>,
) {
  const regionIdSet = new Set(regions.map((region) => region.id))

  const regionWeightPairs = Object.entries(weights).filter(([regionId]) =>
    regionIdSet.has(regionId),
  )

  if (regionWeightPairs.length === 0) {
    return regions[0]
  }

  return chooseRandomRegion(regions, regionWeightPairs)
}

/**
 * Hook to manage the selected provider and region state. The main purpose of this hook is to choose a default provider
 * and region and to filter the regions based on the selected provider.
 */
export function useBoundProviderAndRegionState(regions: NonEmptyArray<Region>): {
  selectedRegion: Region
  selectedProvider: CSP
  providers: CSP[]
  providerRegions: Region[]
  setSelectedRegion: (region: Region) => void
  setSelectedProvider: (provider: CSP) => void
} {
  const defaultRegion = useDefaultRegionChooser(regions)

  const defaultProvider = defaultRegion.csp

  const [selectedProvider, setSelectedProvider] = useState<CSP>(defaultProvider)

  const [selectedRegion, setSelectedRegion] = useState<Region>(defaultRegion)

  const providerRegions = regions.filter((region) => region.csp === selectedProvider)

  const providers = uniqBy(regions, `csp`).map((region) => region.csp)

  return {
    selectedRegion,
    selectedProvider,
    providers,
    providerRegions,
    setSelectedRegion,
    setSelectedProvider: (provider) => {
      const firstProviderRegion = regions.find((region) => region.csp === provider) as Region

      setSelectedRegion(firstProviderRegion)

      setSelectedProvider(provider)
    },
  }
}

export function chooseRandomRegion(
  regions: Region[],
  regionWeightsPairs: Array<[string, number]>,
): Region {
  type WeightedRegionVote = { regionId: string; cumulativeProbability: number }

  const sumByWeight: (pairs: Array<[string, number]>) => number = (pairs) => sumBy(pairs, '1') // '1' is the index of the weight in the tuple

  const totalWeight = sumByWeight(regionWeightsPairs)

  const cumulativeProbabilityPerRegion: WeightedRegionVote[] = regionWeightsPairs.map(
    ([regionId, regionWeight], i, array) => {
      const previousEntries = array.slice(0, i)

      const cumulativeWeight = sumByWeight(previousEntries) + regionWeight

      return {
        regionId,
        cumulativeProbability: cumulativeWeight / totalWeight,
      }
    },
  )

  cumulativeProbabilityPerRegion.sort((a, b) => a.cumulativeProbability - b.cumulativeProbability)

  const random = Math.random()

  const chosenIndex = cumulativeProbabilityPerRegion.findIndex(
    (vote) => vote.cumulativeProbability >= random,
  )

  const chosenRegionId = cumulativeProbabilityPerRegion[chosenIndex]?.regionId

  return regions.find((region) => region.id === chosenRegionId) as Region
}
