/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import type { useEuiTheme } from '@elastic/eui'
import {
  EuiBadge,
  EuiCheckableCard,
  EuiFlexGroup,
  EuiFormFieldset,
  euiScreenReaderOnly,
  useGeneratedHtmlId,
} from '@elastic/eui'

import { useCardStyleOnHover } from './styles'

import type { KeyboardEventHandler, MouseEventHandler } from 'react'
import type { MessageDescriptor } from 'react-intl'

type CheckableCardFieldsetProps<T> = {
  selectedValue: T | undefined
  prefix: string
  options: ReadonlyArray<{ label: MessageDescriptor; value: T }>
  onChange: (value: T) => void
  onSubmit: (value: T) => void
  'data-test-id'?: string
}

function CheckableCardFieldset<T extends string>({
  selectedValue,
  prefix,
  options,
  onChange,
  onSubmit,
  'data-test-id': dataTestId,
}: CheckableCardFieldsetProps<T>) {
  const { formatMessage } = useIntl()

  const [trackMouseClicks, setTrackMouseClicks] = useState(false)

  useEffect(() => {
    if (trackMouseClicks) {
      if (selectedValue) {
        onSubmit(selectedValue)
      }

      setTrackMouseClicks(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]) // Deliberately disregard the full dependency array here, only fire on `radio` state change

  const enterIconHint = (
    <EuiBadge
      className='enterKeyHint'
      iconType='returnKey'
      iconSide='right'
      aria-label={formatMessage({
        id: 'checkable.card.fieldset.option-enter-key-hint',
        defaultMessage: 'Press Enter to select this option.',
      })}
    >
      {formatMessage({
        id: 'checkable.card.fieldset.enter-key',
        defaultMessage: 'Enter',
      })}
    </EuiBadge>
  )

  const htmlId = useGeneratedHtmlId({ prefix })

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLFieldSetElement>>(
    (e) => {
      if (selectedValue && e.key === 'Enter') {
        onSubmit(selectedValue)
      }
    },
    [onSubmit],
  )

  const onMouseDown = useCallback<MouseEventHandler<HTMLFieldSetElement>>((e) => {
    const targetElement = e.target as HTMLElement

    const isCheckableCard = targetElement.closest('.euiCheckableCard') !== null

    if (isCheckableCard) {
      // Note: Because this event fires before the child `onChange` state updates,
      // we can't simply fire onSubmit() here - we have to track
      // a separate state for mouse clicks to count as 'proceeding'
      setTrackMouseClicks(true)
    }
  }, [])

  const cardStyleOnHover = useCardStyleOnHover()

  return (
    <EuiFormFieldset onKeyDown={onKeyDown} onMouseDown={onMouseDown}>
      <EuiFlexGroup direction='column' gutterSize='m'>
        {options.map(({ label, value }) => (
          <EuiCheckableCard
            data-test-id={dataTestId}
            key={value}
            id={`${htmlId}_${value}`}
            label={
              <Fragment>
                {formatMessage(label)}
                {enterIconHint}
              </Fragment>
            }
            value={value}
            checked={selectedValue === value}
            onChange={() => onChange(value)}
            onClick={(e) => {
              const isActualClickEvent = e.pageX !== 0 || e.pageY !== 0

              if (!isActualClickEvent) {
                return
              }

              onSubmit(value)
            }}
            css={[
              customCss,
              {
                width: '100%',
                maxWidth: '460px',
              },
              cardStyleOnHover,
            ]}
          />
        ))}
      </EuiFlexGroup>
    </EuiFormFieldset>
  )
}

function customCss({ euiTheme, colorMode }: ReturnType<typeof useEuiTheme>) {
  return css`
    background-color: ${colorMode === 'DARK' ? euiTheme.colors.body : euiTheme.colors.emptyShade};
    position: relative;

    .enterKeyHint {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: ${euiTheme.size.s};

      /* Hide except for keyboard focus */
      opacity: 0;
      transition: opacity ${euiTheme.animation.fast} ease-in;

      cursor: pointer;
    }

    &:has(:focus-visible) {
      .enterKeyHint {
        opacity: 1;
      }
    }

    /* Visually hide the radio button */
    & > :first-child {
      ${euiScreenReaderOnly()}
    }
  `
}

export default CheckableCardFieldset
