/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { EuiPanel, EuiText, EuiTitle, EuiSpacer } from '@elastic/eui'

import creditCardIllustration from '@/files/credit-card-with-bubbles.svg'

import type { FunctionComponent, ReactElement } from 'react'

export interface Props {
  details: string | ReactElement
  footer?: string | ReactElement
  grow?: boolean
}

const PaymentDetailsCard: FunctionComponent<Props> = ({ details, footer, grow }) => {
  const nextMonth = moment().endOf(`month`).add(1, `days`)
  const nextBillDate = nextMonth.format(`MMM DD, YYYY`)

  return (
    <EuiPanel
      paddingSize='l'
      hasShadow={false}
      hasBorder={true}
      data-test-id='payment-details-card'
      grow={grow}
    >
      <div
        style={{
          backgroundImage: `url("${creditCardIllustration}")`,
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <EuiText>
          <h6>
            <FormattedMessage id='payment-details-card.title' defaultMessage='Next Statement' />
          </h6>
        </EuiText>

        <EuiSpacer size='m' />

        <EuiTitle size='m' data-test-id='nextBillDate'>
          <span>{nextBillDate}</span>
        </EuiTitle>

        <EuiSpacer size='m' />

        {details}

        <EuiSpacer size='l' />

        {footer}
      </div>
    </EuiPanel>
  )
}

export default PaymentDetailsCard
