/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useContext } from 'react'
import { z } from 'zod'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import { noop } from 'lodash'

import { EuiTourStep, EuiButtonEmpty, EuiButton } from '@elastic/eui'

import { useConfig } from '@modules/cui/ConfigContext'
import { useGetSaasCurrentUserMfaDevicesQuery } from '@modules/cloud-lib/users/hooks/mfa'
import { useTypedLocalStorage } from '@modules/utils/useTypedLocalStorage'
import AuthContext from '@modules/auth/context'

// eslint-disable-next-line import/no-restricted-paths
import { userSettingsUrl } from '@/lib/urlBuilder'

import type { DeviceType } from './types'
import type { ReactElement } from 'react'

const SHOW_WEBAUTHN_TOUR = 'SHOW_WEBAUTHN_TOUR'

/*
 * This hook is used to determine whether the user should be shown a tour
 * that introduces them to the WebAuthn feature. There are multiple conditions
 * that must be met in order to show it:
 *
 *  - the app must be userconsole
 *  - user is logged in
 *  - the user does not have an active WebAuthn device
 *  - the tour has not been dismissed
 *  - the user is using username-and-password authentication and can manage MFA devices
 *  - the user menu is closed (for the first tour step)
 *  - the user is not on the settings page (for the first tour step)
 */
const useShowTour = (): [boolean, () => void] => {
  const isUserconsole = useConfig('APP_NAME') === 'userconsole'
  const [showTour, setShowTour] = useTypedLocalStorage(SHOW_WEBAUTHN_TOUR, z.boolean(), true)
  const devicesQuery = useGetSaasCurrentUserMfaDevicesQuery({ enabled: showTour })
  const { authContext } = useContext(AuthContext)

  if (!isUserconsole) {
    return [false, noop]
  }

  if (!showTour) {
    return [false, noop]
  }

  if (authContext.method !== 'username-and-password') {
    return [false, noop]
  }

  const devices = devicesQuery.data?.mfa_devices

  if (devices === undefined) {
    return [false, noop]
  }

  const hasActiveWebAuthnDevice = devices.some(
    (device) => device.device_type === 'WEBAUTHN' && device.status === 'ACTIVE',
  )

  if (hasActiveWebAuthnDevice) {
    return [false, noop]
  }

  return [showTour, () => setShowTour(false)]
}

export const WebAuthnFirstTourStep: React.FunctionComponent<{
  isUserMenuOpen: boolean
  loggedIn: boolean
}> = ({ isUserMenuOpen, loggedIn }) => {
  const {
    push,
    location: { pathname },
  } = useHistory()

  const [showTour, closeTour] = useShowTour()
  const isStepOpen = showTour && loggedIn && !isUserMenuOpen && !pathname.includes('/user/settings')

  return (
    <EuiTourStep
      anchor='#chromeHeader-userMenuPopover'
      content={
        <FormattedMessage
          id='webauthn-tour-step.content'
          defaultMessage='Use phishing-resistant multifactor authentication options to further secure your account.'
        />
      }
      maxWidth={350}
      isStepOpen={isStepOpen}
      step={1}
      stepsTotal={2}
      title={
        <FormattedMessage
          id='webauthn-tour-step.title'
          defaultMessage='Security keys and biometrics now available!'
        />
      }
      subtitle={
        <FormattedMessage
          id='webauthn-tour-step.subtitle'
          defaultMessage='Multifactor authentication'
        />
      }
      onFinish={closeTour}
      footerAction={[
        <EuiButtonEmpty size='s' color='text' onClick={closeTour}>
          <FormattedMessage id='webauthn-tour-step.skip-tour' defaultMessage='Skip tour' />
        </EuiButtonEmpty>,
        <EuiButton
          size='s'
          color='success'
          onClick={() => {
            push(userSettingsUrl())
          }}
        >
          <FormattedMessage id='webauthn-tour-step.take-me-there' defaultMessage='Take me there' />
        </EuiButton>,
      ]}
    />
  )
}

export const WebAuthnSecondTourStep: React.FunctionComponent<{
  children: ReactElement
  type: DeviceType
}> = ({ children, type }) => {
  const [showTour, closeTour] = useShowTour()

  if (type !== 'WEBAUTHN') {
    return children
  }

  return (
    <EuiTourStep
      content={
        <FormattedMessage
          id='webauthn-tour-step.second.content'
          defaultMessage='Set up additional multifactor authentication methods to reinforce the security of your account.'
        />
      }
      maxWidth={350}
      isStepOpen={showTour}
      step={2}
      stepsTotal={2}
      title={
        <FormattedMessage
          id='webauthn-tour-step.second.title'
          defaultMessage='Make your account more secure!'
        />
      }
      subtitle={
        <FormattedMessage
          id='webauthn-tour-step.subtitle'
          defaultMessage='Multifactor authentication'
        />
      }
      onFinish={closeTour}
      footerAction={[
        <EuiButtonEmpty size='s' color='text' onClick={closeTour}>
          <FormattedMessage id='webauthn-tour-step.close-tour' defaultMessage='Close tour' />
        </EuiButtonEmpty>,
      ]}
    >
      {children}
    </EuiTourStep>
  )
}
