/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useContext, useEffect, useState } from 'react'

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import { hasStrongMfaDevice } from '@modules/mfa-lib/devices'
import type { AuthContextData } from '@modules/auth/context'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import AuthContext from '@modules/auth/context'
import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'
import { useProfile } from '@modules/profile-lib/hooks'
import type { UserProfileFromApi } from '@modules/ui-types'
import {
  useGetSaasCurrentUserMfaDevicesQuery,
  useGetSaasCurrentUserMfaEnabled,
} from '@modules/cloud-lib/users/hooks/mfa'

export function useIsMfaEnforced(): boolean | null {
  const [isFlagsUsable, mfaEnforcedFlag] = useMfaEnforcedFeatureFlag()
  const [isMfaEnforced, setIsMfaEnforced] = useState<boolean | null>(null)

  const profile = useProfile()
  const mfaEnabled = useGetSaasCurrentUserMfaEnabled()
  const devices = useGetSaasCurrentUserMfaDevicesQuery().data?.mfa_devices

  const {
    authContext: { method: authMethod },
  } = useContext(AuthContext)

  useEffect(() => {
    if (!isFlagsUsable) {
      return
    }

    if (isMfaEnforced === null) {
      const meetsRequirements = meetsMfaRequirements({
        profile,
        mfaEnabled,
        mfaEnforced: mfaEnforcedFlag,
        authMethod,
        devices,
      })

      if (meetsRequirements !== null) {
        setIsMfaEnforced(!meetsRequirements)
      }
    }
  }, [isFlagsUsable, profile, mfaEnabled, isMfaEnforced, mfaEnforcedFlag, authMethod, devices])

  return isMfaEnforced
}

export function meetsMfaRequirements({
  profile,
  mfaEnabled,
  mfaEnforced,
  authMethod,
  devices,
}: {
  profile: UserProfileFromApi | undefined
  mfaEnabled: boolean | undefined
  mfaEnforced: boolean
  authMethod: AuthContextData['method']
  devices: SaasAuthMfaDeviceResponse[] | undefined
}): boolean | null {
  // loading states
  if (!profile) {
    return null
  }

  if (!devices) {
    return null
  }

  if (mfaEnabled === undefined) {
    return null
  }

  // cases in which there is no need to enforce MFA
  if (!profile.mfa_enforced) {
    // mfaEnforced from profile API, has precedence over feature flag
    return true
  }

  if (!mfaEnforced) {
    // mfaEnforced feature flag
    return true
  }

  if (authMethod !== 'username-and-password') {
    // only enforce if they logged in with traditional credentials instead of e.g. a third party IdP.
    return true
  }

  // cases in which we do need to enforce MFA
  if (!mfaEnabled) {
    // user has MFA disabled, so we need to enforce it, doesn't matter if they have strong devices or not
    return false
  }

  // comes last, if user has strong MFA device, we don't need to enforce MFA
  return hasStrongMfaDevice(devices)
}

function useMfaEnforcedFeatureFlag(): [boolean, boolean] {
  const ldEnabled = Boolean(useConfig(Feature.launchdarklyId))
  const [isFlagsUsable, { mfaEnforced }] = useFlagsWhenLoaded()
  const isGovCloud = Boolean(useConfig(Feature.hideIrrelevantSectionsFromGovCloud))

  if (ldEnabled) {
    return [isFlagsUsable, mfaEnforced]
  }

  if (isGovCloud) {
    // This is effectievely enabling MFA enforcement in GovCloud
    return [true, true]
  }

  // There might be a case where LaunchDarkly is disabled and we are not in GovCloud, like Heroku,
  // which should not enforce MFA.
  return [true, false]
}
