/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

export const ONBOARDING_TOKEN_VALIDITY_WINDOW = 4 * 60 * 60 * 1000 // 4 hours

export const SECURITY_USE_CASE_PREFIX = 'security_use_case'

export const SECURITY_USE_CASES_SIEM = 'siem'
export const SECURITY_USE_CASES_CLOUD_SEC = 'cloud'
export const SECURITY_USE_CASES_EDR = 'edr'
export const SECURITY_USE_CASES_SOMETHING_ELSE = 'other'

export const SECURITY_USE_CASES = [
  SECURITY_USE_CASES_SIEM,
  SECURITY_USE_CASES_CLOUD_SEC,
  SECURITY_USE_CASES_EDR,
  SECURITY_USE_CASES_SOMETHING_ELSE,
] as const

export const MIGRATION_FROM_SIEM_PREFIX = 'migration_from_siem'

export const MIGRATION_FROM_SIEM_NO = 'no'
export const MIGRATION_FROM_SIEM_ORIGIN_SPLUNK = 'splunk'
export const MIGRATION_FROM_SIEM_ORIGIN_OTHER = 'other'

export const MIGRATION_FROM_SIEM_ORIGIN = [
  MIGRATION_FROM_SIEM_ORIGIN_SPLUNK,
  MIGRATION_FROM_SIEM_ORIGIN_OTHER,
] as const
