/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import moment from 'moment'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiCode,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui'

import type { ClusterCredentials, DeploymentTemplateInfoV2 } from '@modules/cloud-api/v1/types'
import CopyButton from '@modules/cui/CopyButton'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import type { StackDeployment } from '@modules/ui-types'

import { getSliderPrettyName } from '@/lib/sliders/messages'

import { getDedicatedTemplateType } from '../../lib/deploymentTemplates/metadata'

import type { FC } from 'react'

import './stackElasticCredentials.scss'

export type Props = {
  deployment: StackDeployment
  deploymentTemplate?: DeploymentTemplateInfoV2
  onlyShowCredentials?: boolean
  credentials: ClusterCredentials | undefined
  onDownloadCredentials?: () => void
}

const messages = defineMessages({
  title: {
    id: `elastic-credentials-header.label`,
    defaultMessage: `Save your Elasticsearch and Kibana password`,
  },
  description: {
    id: `elastic-credentials-header.description`,
    defaultMessage: `These credentials provide superuser access to Elasticsearch and Kibana in this deployment. The password won’t be shown again.`,
  },
  titleDedicated: {
    id: `elastic-credentials-header.label-dedicated`,
    defaultMessage: `Save your {sliderName} user password`,
  },
  descriptionDedicated: {
    id: `elastic-credentials-header.description-dedicated`,
    defaultMessage: `These credentials provide owner access to your {sliderName} deployment. The password won’t be shown again.`,
  },
  download: {
    id: `elastic-credentials-blob-download-button.label`,
    defaultMessage: `Download`,
  },
  copy: {
    id: `elastic-credentials-copy.label`,
    defaultMessage: `Copy`,
  },
  username: {
    id: `cluster-new-credentials.username`,
    defaultMessage: `Username`,
  },
  password: {
    id: `cluster-new-credentials-password`,
    defaultMessage: `Password`,
  },
})

const StackElasticCredentials: FC<Props> = ({
  deployment,
  credentials,
  onlyShowCredentials,
  onDownloadCredentials,
  deploymentTemplate,
}) => {
  const { formatMessage } = useIntl()

  const {
    euiTheme: {
      colors: {
        darkestShade: euiDarkestShade,
        darkShade: euiDarkShade,
        lightestShade: euiLightestShade,
      },
      size: { m: euiSizeM, xxl: euiSizeXxl },
    },
  } = useEuiTheme()

  if (!credentials) {
    return null
  }

  const { id } = deployment
  const { username, password } = credentials

  const userNameAndPasswordStyle = css({
    fontSize: '1rem',
    color: euiDarkestShade,
  })
  const credentialsLabelStyle = css({
    color: euiDarkShade,
  })

  const fileName = `credentials-${id.slice(0, 6)}-${moment().format(`YYYY-MMM-DD--HH_mm_ss`)}.csv`

  const listItems = [
    {
      title: (
        <EuiText className='elastic-credentials-label' size='s' css={credentialsLabelStyle}>
          <FormattedMessage {...messages.username} />
        </EuiText>
      ),
      description: (
        <EuiFlexGroup style={{ alignItems: 'center' }} justifyContent='center' gutterSize='none'>
          <EuiFlexItem grow={false}>
            <EuiCode
              data-test-id='elastic-credentials-username'
              className='elastic-credentials-username'
              css={userNameAndPasswordStyle}
              transparentBackground={true}
            >
              {username}
            </EuiCode>
          </EuiFlexItem>
          <EuiFlexItem className='copy-password-button' grow={false}>
            <CopyButton
              value={username}
              aria-label={formatMessage({
                id: 'elastic-credentials-copy-username.label',
                defaultMessage: 'Copy username to clipboard',
              })}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
    {
      title: (
        <EuiText className='elastic-credentials-label' size='s' css={credentialsLabelStyle}>
          <FormattedMessage {...messages.password} />
        </EuiText>
      ),
      description: (
        <EuiFlexGroup style={{ alignItems: 'center' }} justifyContent='center' gutterSize='none'>
          <EuiFlexItem grow={false}>
            <EuiCode
              data-test-id='elastic-credentials-password'
              className='elastic-credentials-password'
              css={userNameAndPasswordStyle}
              transparentBackground={true}
            >
              {password}
            </EuiCode>
          </EuiFlexItem>
          <EuiFlexItem className='copy-password-button' grow={false}>
            <CopyButton
              value={password}
              aria-label={formatMessage({
                id: 'elastic-credentials-copy-password.label',
                defaultMessage: 'Copy password to clipboard',
              })}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
  ]

  return onlyShowCredentials ? renderStackCredentials() : renderStackCredentialsPanel()

  function renderStackCredentials() {
    if (!credentials) {
      return null
    }

    const downloadButton = (
      // eslint-disable-next-line @elastic/eui/href-or-on-click
      <EuiButton
        data-test-id='elastic-credentials-download-button'
        download={fileName}
        href={createBlobUrl(credentials)}
        onClick={onDownloadCredentials}
        iconType='download'
        css={{ padding: [0, euiSizeM], minWidth: 153 }}
      >
        <FormattedMessage {...messages.download} />
      </EuiButton>
    )

    const elasticCredentialsStyle = css({
      backgroundColor: euiLightestShade,
      paddingTop: euiSizeXxl,
      paddingBottom: euiSizeXxl,
    })

    return (
      <EuiFlexGroup
        direction='column'
        alignItems='center'
        css={elasticCredentialsStyle}
        gutterSize='l'
      >
        <PrivacySensitiveContainer>
          <EuiDescriptionList
            data-test-id='elasticCredentials'
            listItems={listItems}
            align='center'
          />
        </PrivacySensitiveContainer>
        <EuiFlexGroup gutterSize='s' direction='column'>
          {downloadButton}
          <EuiButtonEmpty
            data-test-id='continue-without-download'
            onClick={() => onDownloadCredentials?.()}
          >
            <FormattedMessage defaultMessage='Skip' id='deployment-waiting-experience.skip' />
          </EuiButtonEmpty>
        </EuiFlexGroup>
      </EuiFlexGroup>
    )
  }

  function renderStackCredentialsPanel() {
    const dedicatedTemplateType = getDedicatedTemplateType(deploymentTemplate)

    let title = <FormattedMessage {...messages.title} />
    let description = <FormattedMessage {...messages.description} />

    // Checking explicitly against appsearch, until it's known if this is safe for other template types
    if (dedicatedTemplateType === `appsearch`) {
      const sliderName = (
        <FormattedMessage {...getSliderPrettyName({ sliderInstanceType: dedicatedTemplateType })} />
      )

      title = <FormattedMessage {...messages.titleDedicated} values={{ sliderName }} />
      description = <FormattedMessage {...messages.descriptionDedicated} values={{ sliderName }} />
    }

    return (
      <PrivacySensitiveContainer>
        <EuiFlexItem data-test-id='elasticCredentials' className='elastic-credentials'>
          <EuiTitle size='xs'>
            <h3 data-test-id='elastic-credentials-info-title'>{title}</h3>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiText color='subdued'>
            <p data-test-id='elastic-credentials-info-description'>{description}</p>
          </EuiText>
          <EuiHorizontalRule margin='s' />
          {renderStackCredentials()}
        </EuiFlexItem>
        <EuiSpacer size='m' />
      </PrivacySensitiveContainer>
    )
  }
}

function createBlobUrl(credentials: ClusterCredentials): string {
  const blob = new Blob([createCSVFromCredentials(credentials)], {
    type: `text/csv;charset=utf-8`,
  })

  return URL.createObjectURL(blob)
}

function createCSVFromCredentials(credentials: ClusterCredentials): string {
  return [Object.keys(credentials).join(), Object.values(credentials).join()].join(`\n`)
}

export default StackElasticCredentials
