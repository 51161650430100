/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { capitalize } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import useOrganization from '@modules/billing-lib/useOrganization'

// eslint-disable-next-line import/no-restricted-paths
import { numericDateTime } from '@/config/dates'

// Centralized place to display notifications/warnings on billings pages
const BillingNotificationsCenter = () => {
  const orgQuery = useOrganization()

  const renderPendingSubscriptionUpdateNotice = () => {
    if (!orgQuery.data || !orgQuery.data.subscription.pending_level) {
      return null
    }

    return (
      <Fragment>
        <EuiSpacer size='m' />
        <EuiCallOut
          size='s'
          title={
            <FormattedMessage
              id='pending-subscription-update-notice.message'
              defaultMessage='The subscription update {pending_level} is in process. The changes become effective on {pending_level_valid_from}.'
              values={{
                pending_level: capitalize(orgQuery.data.subscription.pending_level),
                pending_level_valid_from: (
                  <FormattedDate
                    data-test-id='expiration-date-column-value'
                    value={orgQuery.data.subscription.pending_level_valid_from}
                    {...numericDateTime}
                  />
                ),
              }}
            />
          }
          iconType='clock'
          data-test-id='pending-subscription-update-notice'
        />
      </Fragment>
    )
  }

  return renderPendingSubscriptionUpdateNotice()
}

export default BillingNotificationsCenter
