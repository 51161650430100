/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  activateLineItemsUrl,
  getBillingHistoryUrl,
  getCostsOverviewUrl,
  getLineItemsUrl,
  getMarketplaceBillingDetailsUrl,
  getPrepaidsUrl,
} from '@modules/cloud-api/v1/urls'
import type {
  GetBillingHistoryUrlParams,
  GetCostsOverviewUrlParams,
  GetMarketplaceBillingDetailsUrlParams,
  GetPrepaidsUrlParams,
} from '@modules/cloud-api/v1/urls'
import type { ActivateLineItemsRequest } from '@modules/cloud-api/v1/types'
import type { TimePeriod } from '@modules/ui-types'

import {
  fetchPrepaidBalanceLineItemsRequest,
  fetchAccountCostsOverviewRequest,
} from '@/reducers/asyncRequests/registry'
import asyncRequest, { resetAsyncRequest } from '@/actions/asyncRequests'
import {
  ACTIVATE_LINE_ITEMS,
  FETCH_ACCOUNT_COSTS_OVERVIEW,
  FETCH_BILLING_HISTORY,
  FETCH_MARKETPLACE_BILLING_DETAILS,
  FETCH_ORGANIZATION_PREPAIDS,
  FETCH_PREPAID_BALANCE_LINE_ITEMS,
} from '@/constants/actions'
import { getPrepaidBalanceLineItems, getAccountCostsOverview } from '@/reducers/billing'

import type { ReduxState } from '@/types/redux'

export type fetchDeploymentsCostsParams = {
  organizationId: string
  timePeriod: TimePeriod
}

function fetchAccountCostsOverview({ organizationId, from, to }: GetCostsOverviewUrlParams) {
  const url = getCostsOverviewUrl({ organizationId, from, to })
  const crumbs = from && to ? [`${from}_${to}`] : undefined

  return asyncRequest({
    type: FETCH_ACCOUNT_COSTS_OVERVIEW,
    url,
    meta: { organizationId, from, to },
    crumbs,
  })
}

interface ShouldFetchAccountCostOverviewProps extends GetCostsOverviewUrlParams {
  state: ReduxState
}

function shouldFetchAccountCostOverview({
  state,
  organizationId,
  from,
  to,
}: ShouldFetchAccountCostOverviewProps): boolean {
  const accountCostOverviewRequest =
    from && to
      ? fetchAccountCostsOverviewRequest(state, `${from}_${to}`)
      : fetchAccountCostsOverviewRequest(state)

  if (accountCostOverviewRequest.inProgress) {
    return false
  }

  return !getAccountCostsOverview({ state, organizationId, from, to })
}

export function fetchAccountCostsOverviewIfNeeded({
  organizationId,
  from,
  to,
}: GetCostsOverviewUrlParams) {
  return (dispatch, getState) => {
    if (!shouldFetchAccountCostOverview({ state: getState(), organizationId, from, to })) {
      return Promise.resolve()
    }

    return dispatch(fetchAccountCostsOverview({ organizationId, from, to }))
  }
}

function shouldFetchPrepaidBalanceLineItems(state: ReduxState, organizationId: string): boolean {
  const prepaidBalanceLineItemsRequest = fetchPrepaidBalanceLineItemsRequest(state)

  if (prepaidBalanceLineItemsRequest.inProgress) {
    return false
  }

  return !getPrepaidBalanceLineItems(state, organizationId)
}

export function fetchPrepaidBalanceLineItemsIfNeeded({
  organizationId,
}: {
  organizationId: string
}) {
  return (dispatch, getState) => {
    if (!shouldFetchPrepaidBalanceLineItems(getState(), organizationId)) {
      return Promise.resolve()
    }

    return dispatch(fetchPrepaidBalanceLineItems({ organizationId }))
  }
}

function fetchPrepaidBalanceLineItems({ organizationId }: { organizationId: string }) {
  const url = getLineItemsUrl({ organizationId })

  return asyncRequest({
    type: FETCH_PREPAID_BALANCE_LINE_ITEMS,
    url,
    meta: { organizationId },
  })
}

export function fetchBillingHistory(params: GetBillingHistoryUrlParams) {
  const url = getBillingHistoryUrl(params)

  return asyncRequest({
    type: FETCH_BILLING_HISTORY,
    url,
    meta: { organizationId: params.organizationId },
  })
}

export function fetchOrganizationPrepaids(params: GetPrepaidsUrlParams) {
  const url = getPrepaidsUrl(params)

  return asyncRequest({
    type: FETCH_ORGANIZATION_PREPAIDS,
    url,
    meta: { organizationId: params.organizationId },
  })
}

export function activateLineItems(payload: ActivateLineItemsRequest) {
  const url = activateLineItemsUrl()
  const { organization_id } = payload

  return asyncRequest({
    type: ACTIVATE_LINE_ITEMS,
    method: `PUT`,
    url,
    meta: { organization_id },
    payload,
  })
}

export const resetActivateLineItemsRequest = () => resetAsyncRequest(ACTIVATE_LINE_ITEMS)

export function fetchMarketplaceBillingDetails(params: GetMarketplaceBillingDetailsUrlParams) {
  const url = getMarketplaceBillingDetailsUrl(params)
  const { organizationId } = params

  return asyncRequest({
    type: FETCH_MARKETPLACE_BILLING_DETAILS,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}
