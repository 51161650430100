/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiSuperSelectProps } from '@elastic/eui'
import { EuiSuperSelect, EuiText } from '@elastic/eui'

import type { Region } from '@modules/cluster-user-api/v1/types'

type ServerlessRegionSelectorProps = {
  selectedRegion: Region
  allowedRegions: Region[]
  comingSoon?: boolean
  onSelectionChange: (value: Region) => void
} & Omit<EuiSuperSelectProps<Region>, 'options' | 'valueOfSelected' | 'onChange'>

const ServerlessRegionSelector = ({
  selectedRegion,
  allowedRegions,
  comingSoon,
  onSelectionChange,
  ...props
}: ServerlessRegionSelectorProps) => {
  const allRegions = allowedRegions.map((region) => ({
    value: region.id,
    inputDisplay: <EuiText size='s'>{region.name}</EuiText>,
    disabled: false,
  }))

  if (comingSoon) {
    allRegions.push({
      inputDisplay: (
        <EuiText textAlign='center' color='subdued' size='xs'>
          <FormattedMessage
            id='provider-selector.coming-soon-elastic-cloud-hosted-span'
            defaultMessage={
              'Additional providers and locations are available on <strong>Elastic Cloud Hosted</strong>'
            }
            values={{
              strong: (text: string) => <strong>{text}</strong>,
            }}
          />
        </EuiText>
      ),
      disabled: true,
      value: 'elastic-cloud-hosted',
    })
  }

  return (
    <EuiSuperSelect
      fullWidth={true}
      valueOfSelected={selectedRegion.id}
      options={allRegions}
      onChange={(regionId) =>
        onSelectionChange(allowedRegions.find((reg) => regionId === reg.id) || selectedRegion)
      }
      {...props}
    />
  )
}

export default ServerlessRegionSelector
