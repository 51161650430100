/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiPopover, EuiText } from '@elastic/eui'

import { useSaasUser } from '@modules/profile-lib/hooks'
import UnifiedTrialSummaryModal from '@modules/trial-components/UnifiedTrialSummaryModal'

// eslint-disable-next-line import/no-restricted-paths
import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'

import CreateProjectButtonBase from './CreateProjectButtonBase'
import { useCreateProjectButtonProps } from './hooks'

import type { FC } from 'react'

export interface Props {
  hasAccentColor?: boolean
}

const popoverContentStyle = css({ maxWidth: '310px' })

const CreateProjectButton: FC<Props> = ({ hasAccentColor }) => {
  const { isDisabled, inActiveTrial, canCreateProject } = useCreateProjectButtonProps()
  const saasUser = useSaasUser()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const togglePopover = useCallback(() => setIsPopoverOpen((currValue) => !currValue), [])
  const closePopover = useCallback(() => setIsPopoverOpen(false), [])

  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false)
  const showTrialModal = useCallback(() => setIsTrialModalOpen(true), [])
  const hideTrialModal = useCallback(() => setIsTrialModalOpen(false), [])

  if (inActiveTrial) {
    return (
      <EuiPopover
        button={
          <CreateProjectButtonBase
            hasAccentColor={hasAccentColor}
            isDisabled={isDisabled}
            onClickFallback={togglePopover}
            canCreateProject={canCreateProject}
          />
        }
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        panelPaddingSize='m'
        anchorPosition='upCenter'
      >
        <EuiText css={popoverContentStyle}>
          <FormattedMessage
            id='portal-projects.create-project-limited-to-one'
            defaultMessage='Trials are limited to one project at a time. {subscribe} to create additional projects.'
            values={{
              subscribe: (
                <CreditCardModalButton onCloseModal={closePopover}>
                  <FormattedMessage id='portal.subscribe' defaultMessage='Subscribe' />
                </CreditCardModalButton>
              ),
            }}
          />
        </EuiText>
      </EuiPopover>
    )
  }

  return (
    <Fragment>
      {isTrialModalOpen && saasUser && (
        <UnifiedTrialSummaryModal saasUser={saasUser} onClose={hideTrialModal} />
      )}

      <CreateProjectButtonBase
        hasAccentColor={hasAccentColor}
        isDisabled={isDisabled}
        onClickFallback={showTrialModal}
        canCreateProject={canCreateProject}
      />
    </Fragment>
  )
}

export default CreateProjectButton
