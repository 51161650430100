/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useParams } from 'react-router'

import { useConfig } from '@modules/cui/ConfigContext'
import { useGetOrganizationQuery, useProfile } from '@modules/profile-lib/hooks'

// Hook returns organization data
// Works in both Admin and User consols
const useOrganization = () => {
  const appName = useConfig('APP_NAME')
  // in Admin Console, orgId should be taken from URL
  const { organizationId: orgIdFromUrl } = useParams<{ organizationId?: string }>()
  // in User Console we need to fetch profile to get orgId
  const profile = useProfile({ enabled: appName === 'userconsole' })
  const orgId = orgIdFromUrl || profile?.organization_id
  const organizationQuery = useGetOrganizationQuery(orgId, { enabled: !!orgId })

  return organizationQuery
}

// ts-unused-exports:disable-next-line
export default useOrganization
