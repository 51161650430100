/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EuiText } from '@elastic/eui'

import { StepLayout } from '@modules/cui/Step/StepLayout'
import {
  MIGRATION_FROM_SIEM_NO,
  MIGRATION_FROM_SIEM_ORIGIN_OTHER,
  MIGRATION_FROM_SIEM_ORIGIN_SPLUNK,
  MIGRATION_FROM_SIEM_PREFIX,
} from '@modules/discovery-questions-lib/constants'
import type { MigrationFromSiemOption } from '@modules/discovery-questions-lib/types'

import CheckableCardFieldset from './CheckableCardFieldset'

import type { FunctionComponent } from 'react'

type Props = {
  isMigratingFromSiem: MigrationFromSiemOption | undefined
  onChangeMigratingFromSiem: (value: MigrationFromSiemOption) => void
  onSubmitMigratingFromSiem: (value: MigrationFromSiemOption) => void
}

export const SplunkTrademarkMessage = () => (
  <EuiText color='subdued' size='xs'>
    <i>
      <FormattedMessage
        id='discovery-questions-migrating_from_siem.splunk-trademark'
        defaultMessage='Splunk and related marks are trademarks or registered trademarks of Splunk LLC in the United States and other countries.'
      />
    </i>
  </EuiText>
)

const MigrationFromSiemChoice: FunctionComponent<Props> = ({
  isMigratingFromSiem,
  onChangeMigratingFromSiem: onChangeMigratingFromSiem,
  onSubmitMigratingFromSiem: onSubmitMigratingFromSiem,
}: Props) => {
  const { formatMessage } = useIntl()

  const options = [
    {
      label: {
        id: 'discovery-questions-migrating_from_siem.radio-1',
        defaultMessage: 'Yes, from Splunk.',
      },
      value: MIGRATION_FROM_SIEM_ORIGIN_SPLUNK,
    },
    {
      label: {
        id: 'discovery-questions-migrating_from_siem.radio-2',
        defaultMessage: 'Yes, from another provider.',
      },
      value: MIGRATION_FROM_SIEM_ORIGIN_OTHER,
    },
    {
      label: {
        id: 'discovery-questions-migrating_from_siem.radio-2',
        defaultMessage: 'No',
      },
      value: MIGRATION_FROM_SIEM_NO,
    },
  ] as const

  const title = formatMessage({
    id: `discovery-questions-migrating_from_siem.title`,
    defaultMessage: `Are you migrating from an existing SIEM?`,
  })

  return (
    <StepLayout title={title}>
      <CheckableCardFieldset<MigrationFromSiemOption>
        data-test-id='signup.discovery-questions.migrating_from_siem'
        selectedValue={isMigratingFromSiem}
        prefix={MIGRATION_FROM_SIEM_PREFIX}
        options={options}
        onChange={onChangeMigratingFromSiem}
        onSubmit={onSubmitMigratingFromSiem}
      />
    </StepLayout>
  )
}

export default MigrationFromSiemChoice
