/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'querystring'

import { useContext, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'

import { putDiscoveryQuestions } from '@modules/discovery-questions-api/callers'
import { invalidateGetUserProfileQuery, useProfile } from '@modules/profile-lib/hooks'
import type { QueryFunctionReturnType } from '@modules/query/types'
import { useTypedLocalStorage } from '@modules/utils/useTypedLocalStorage'

// eslint-disable-next-line import/no-restricted-paths

import { useSessionStorageBackedState } from '../utils/hooks/useLocalStorage'
import ProfileContext from '../profile-lib/ProfileContext'

import {
  getKibanaDeploymentDeepLink,
  getKibanaProjectDeepLink,
  isServerlessOnboardingToken,
  isStatefulOnboardingToken,
  isValidOnboardingToken,
  OnboardingToken,
} from './steps'
import { isWithinLast4Hours, ResourceType } from './utils'

import type { AnyUseCaseDetail } from './types'
import type {
  ExperienceLevelOptionsType,
  ExperienceLevelOptionsValueType,
  TrialIntentOptionsType,
  TrialIntentOptionsValueType,
  UseCaseOptionsType,
  UseCaseOptionsValueType,
} from './utils'

export type DiscoveryQuestionsStateType = {
  full_name: string
  company: string
  experience_level: ExperienceLevelOptionsType
  use_case: UseCaseOptionsType
  trial_intent: TrialIntentOptionsType
  use_case_detail?: AnyUseCaseDetail
}

export interface DiscoveryQuestionsPayload {
  full_name: string
  company: string
  experience_level: ExperienceLevelOptionsValueType
  trial_intent: TrialIntentOptionsValueType
  use_case: UseCaseOptionsValueType
  use_case_detail?: string
  onboarding_token?: string
}

export const usePutDiscoveryQuestionsMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof putDiscoveryQuestions>,
    { message },
    { body: DiscoveryQuestionsPayload }
  >({
    mutationFn: ({ body }: { body: DiscoveryQuestionsPayload }) => putDiscoveryQuestions(body),
    onSuccess: () => {
      invalidateGetUserProfileQuery()
    },
  })

/*
 * Evaluate the onboarding token from the query parameters in the URL
 */
export const useOnboardingToken = (): OnboardingToken | undefined => {
  const { search } = useLocation()
  const query = parse(search.slice(1))
  const onboardingTokenFromQuerystring = query.onboarding_token as string | undefined

  const onboardingToken = isValidOnboardingToken(onboardingTokenFromQuerystring)
    ? onboardingTokenFromQuerystring
    : undefined

  const [value, setValue] = useTypedLocalStorage(
    'onboardingToken',
    z.object({
      token: z.enum(getValues(OnboardingToken)).optional(),
      created: z.number(),
    }),
    { token: onboardingToken, created: new Date().getTime() },
  )

  // update stored value when a different onboarding token appears
  useEffect(() => {
    if (onboardingToken && onboardingToken !== value.token) {
      setValue({ token: onboardingToken, created: new Date().getTime() })
    }
  }, [onboardingToken, value, setValue])

  return isWithinLast4Hours(value.created) ? value.token : undefined
}

/*
 * Evaluate the onboarding token from the profile context
 */
export const useOnboardingTokenFromProfile = (entityCount?: number) => {
  const profile = useContext(ProfileContext)

  const userTrials = profile.user?.trials ?? []
  const startTrial = userTrials.reduce((latest, trial) => {
    const date = new Date(trial.start)
    return date > latest ? date : latest
  }, new Date(0))
  const addOnboardingTokenQuery = Boolean(
    profile.user?.is_trial &&
      (entityCount === undefined || entityCount === 1) &&
      profile.user.data.discovery?.onboarding_token &&
      isWithinLast4Hours(startTrial),
  )

  const onboardingToken = addOnboardingTokenQuery
    ? profile.user?.data.discovery?.onboarding_token
    : undefined

  return onboardingToken
}

function getValues<T extends Record<string, any>>(obj: T) {
  return Object.values(obj) as [(typeof obj)[keyof T]]
}

export type UseUseCaseDetails = { security?: string }

export const useUseCaseDetails = (): UseUseCaseDetails => {
  const profile = useProfile()
  const useCaseDetailsRaw = profile?.data.discovery?.use_case_detail

  let useCaseDetailsParsed: { [key: string]: any } | undefined

  try {
    useCaseDetailsParsed = useCaseDetailsRaw ? JSON.parse(useCaseDetailsRaw) : undefined
  } catch {
    useCaseDetailsParsed = undefined
  }

  const useCaseDetails: UseUseCaseDetails = {}

  if (useCaseDetailsParsed) {
    Object.keys(useCaseDetailsParsed).forEach((key) => {
      useCaseDetails[key] = JSON.stringify(useCaseDetailsParsed[key])
    })
  }

  return useCaseDetails
}

export const useKibanaDeeplinkForCurrentResource = (
  resourceType: ResourceType,
  isUserTrial: boolean,
  attributesAvailable: { security: boolean; token: boolean },
  onboardingToken?: OnboardingToken,
) => {
  const useCaseDetails = useUseCaseDetails()
  const hasAnyUseCaseDetails = Object.values(useCaseDetails).some((value) => value)

  let kibanaDeepLink: string | undefined

  const hasOnboardingInfoToSaveInKibana = onboardingToken || hasAnyUseCaseDetails

  if (isUserTrial && hasOnboardingInfoToSaveInKibana) {
    const isServerless = resourceType === ResourceType.Serverless

    if (isServerless && (isServerlessOnboardingToken(onboardingToken) || hasAnyUseCaseDetails)) {
      kibanaDeepLink = getKibanaProjectDeepLink(onboardingToken, useCaseDetails)
    } else if (isStatefulOnboardingToken(onboardingToken) || hasAnyUseCaseDetails) {
      kibanaDeepLink = getKibanaDeploymentDeepLink(
        onboardingToken,
        attributesAvailable,
        useCaseDetails,
      )
    }
  }

  return kibanaDeepLink
}

/**
 * Hook to manage the state of the answers to the discovery questions
 * Stores the answers in the session storage so that they are not lost when accidentally reloading the page
 * @param initialState - state coming from the onboarding token
 */
export const useAnswersState = ({
  initialState,
}: {
  initialState: Partial<DiscoveryQuestionsStateType>
}) => {
  const [answers, setAnswers] = useSessionStorageBackedState(
    'discovery-questions-answers',
    initialState,
  )

  return {
    answers,
    setAnswer: (answer: Partial<DiscoveryQuestionsStateType>) => {
      setAnswers({ ...answers, ...answer })
    },
  }
}
