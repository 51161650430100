/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'

import { EuiSpacer } from '@elastic/eui'

import BillingNotificationsCenter from '.'

import type { ComponentType, FunctionComponent } from 'react'

function withBillingNotificationsCenter<P>(WrappedComponent: ComponentType<P>): ComponentType<P> {
  const WithBillingNotificationsCenterComponent: FunctionComponent<P> = (props) => (
    <Fragment>
      <BillingNotificationsCenter />
      <EuiSpacer size='m' />
      <WrappedComponent {...props} />
    </Fragment>
  )

  return WithBillingNotificationsCenterComponent
}

export default withBillingNotificationsCenter
