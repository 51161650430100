/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/react'

import { EuiButtonIcon, useEuiTheme } from '@elastic/eui'

import type { CSSProperties } from 'react'

type ChatIframeProps = {
  styles?: CSSProperties
  isWidgetOpen: boolean
  handleWindowMessage: (event: MessageEvent, chatIframe: HTMLIFrameElement) => void
  hideWidget: () => void
  chatUrl: string
}

const ChatIframe = ({
  chatUrl,
  isWidgetOpen,
  handleWindowMessage,
  hideWidget,
  styles,
}: ChatIframeProps) => {
  const theme = useEuiTheme()
  const initialStyle: CSSProperties = {
    position: 'fixed' as const,
    bottom: '30px',
    right: '30px',
    height: 0,
    width: 0,
    display: 'block',
    zIndex: -1,
    colorScheme: 'light',
  }
  const [chatIframe, setChatIframe] = useState<HTMLIFrameElement | null>(null)
  const onRefChange = useCallback((node: HTMLIFrameElement) => {
    setChatIframe(node)
  }, [])

  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      if (!chatIframe) {
        return
      }

      handleWindowMessage(event, chatIframe)
    }

    if (!chatIframe) {
      return
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [chatIframe, handleWindowMessage, chatUrl])

  const closeButtonStyle = css`
    z-index: 99999;
    position: fixed;
    bottom: 85px;
    right: 85px;
    border-radius: 4px;
    inline-size: 24px;
    block-size: 24px;
    appearance: none;
    cursor: pointer;
    color: ${theme.euiTheme.colors.primary};
    background-color: ${theme.euiTheme.colors.backgroundBasePrimary};
  `

  return (
    <React.Fragment>
      {isWidgetOpen && (
        <EuiButtonIcon
          size='s'
          display='base'
          iconType='cross'
          color='primary'
          onClick={hideWidget}
          css={closeButtonStyle}
        />
      )}
      <iframe
        data-test-id='drift-chat'
        style={{ ...initialStyle, ...styles }}
        ref={onRefChange}
        src={chatUrl}
      />
    </React.Fragment>
  )
}

export default ChatIframe
