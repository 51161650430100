/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router'

import { useGetDeploymentQuery } from '@modules/deployment-creation-api/hooks'
import { useGetProjectQuery } from '@modules/project-user-lib/hooks/get'
import { isProjectType } from '@modules/project-creation-lib/types'

import { useGetUserProfileQuery } from '../profile-lib/hooks'

import { useKibanaLinks } from './hooks/useKibanaLinks'

import type { FC } from 'react'

const OpenKibanaRedirectPage: FC = () => {
  const { data: profile } = useGetUserProfileQuery()
  const querySearch = new URLSearchParams(useLocation().search)
  const onboardingTokenParam = querySearch.get('onboarding_token')
  const { resourceType, id } = useParams<{ resourceType: string; id: string }>()

  const isDeployment = resourceType === 'deployment'
  const { data: deployment } = useGetDeploymentQuery(id, { enabled: isDeployment })

  const isProject = isProjectType(resourceType)
  const { data: project } = useGetProjectQuery(
    isProject ? resourceType : 'elasticsearch', // unconditional hook sanity,
    id,
    { enabled: isProject },
  )

  const { deploymentLink, projectLink } = useKibanaLinks({
    profile,
    deployment,
    onboardingToken: onboardingTokenParam,
    project,
  })

  let href: string | undefined

  if (deploymentLink) {
    href = deploymentLink
  }

  if (projectLink) {
    href = projectLink
  }

  useEffect(() => {
    if (href) {
      window.location.href = href
    }
  }, [href])

  return null
}

export default OpenKibanaRedirectPage
