/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiText } from '@elastic/eui'

import genericMessages from '@modules/project-lib/genericMessages'
import type { AnyUserProject } from '@modules/project-user-api/types'
import history from '@modules/utils/history'
import { projectUrl } from '@modules/project-user-lib/urls'
import { useIsProjectInitializing } from '@modules/project-user-lib/hooks/status'
import type { Region } from '@modules/cluster-user-api/v1/types'
import { isProjectSuspended } from '@modules/project-lib/suspended'
import { getKibanaRedirectPageUrl } from '@modules/kibana-redirect/url'
import FormattedRegionAndProvider from '@modules/project-components/FormattedRegionAndProvider'

const ProjectNameColumnContent = ({
  name,
  project,
  onboardingToken,
}: {
  name: AnyUserProject['name']
  project: AnyUserProject
  onboardingToken?: string
}) => {
  const isInitializing = useIsProjectInitializing(project)

  if (isInitializing) {
    return (
      <EuiText size='s' color='subdued'>
        {name} (<FormattedMessage {...genericMessages.initializing} />)
      </EuiText>
    )
  }

  const isSuspended = isProjectSuspended(project)

  if (isSuspended) {
    return (
      <EuiText size='s' color='subdued'>
        {name} (<FormattedMessage {...genericMessages.suspended} />)
      </EuiText>
    )
  }

  const href = getKibanaRedirectPageUrl(project.type, project.id, onboardingToken)

  return (
    <EuiText size='s'>
      <strong>
        <EuiLink color='primary' href={href}>
          {name}
        </EuiLink>
      </strong>
    </EuiText>
  )
}

const ActionColumnContent = ({
  project,
  onboardingToken,
}: {
  project: AnyUserProject
  onboardingToken?: string
}) => {
  const isInitializing = useIsProjectInitializing(project)
  const isSuspended = isProjectSuspended(project)
  const { formatMessage } = useIntl()

  const openLabel = formatMessage(genericMessages.open)
  const manageLabel = formatMessage(genericMessages.manage)

  const href = getKibanaRedirectPageUrl(project.type, project.id, onboardingToken)

  return (
    <EuiFlexGroup gutterSize='l' justifyContent='flexEnd' alignItems='baseline' responsive={false}>
      <EuiFlexItem grow={false}>
        {isInitializing || isSuspended || (
          <EuiLink
            href={href}
            aria-label={formatMessage(genericMessages.linkActionAriaLabel, {
              action: openLabel,
              name: project.name,
            })}
          >
            {openLabel}
          </EuiLink>
        )}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiLink
          href={projectUrl(project.type, project.id)}
          onClick={(e) => {
            e.preventDefault()
            history.push(projectUrl(project.type, project.id))
          }}
          aria-label={formatMessage(genericMessages.linkActionAriaLabel, {
            action: manageLabel,
            name: project.name,
          })}
        >
          {manageLabel}
        </EuiLink>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export const getProjectNameColumn = (onboardingToken?: string) => ({
  name: <FormattedMessage {...genericMessages.project} />,
  field: 'name',
  sortable: ({ name }) => name.toLowerCase(),
  render: (name: AnyUserProject['name'], project: AnyUserProject) => (
    <ProjectNameColumnContent name={name} project={project} onboardingToken={onboardingToken} />
  ),
})

export const regionColumn = (regions: Region[] | undefined) => ({
  name: (
    <FormattedMessage id='projects.list.header.region' defaultMessage='Cloud provider & region' />
  ),
  render: ({ region_id }: AnyUserProject) => (
    <FormattedRegionAndProvider regionId={region_id} allRegions={regions} />
  ),
})

export const getActionsColumn = (onboardingToken?: string) => ({
  name: <FormattedMessage {...genericMessages.actions} />,
  align: 'right' as const,
  render: (project: AnyUserProject) => (
    <ActionColumnContent project={project} onboardingToken={onboardingToken} />
  ),
})
