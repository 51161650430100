/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHideFor,
  EuiImage,
  EuiListGroup,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import { isMarketplaceCustomer } from '@modules/billing-lib/billingDetails/utils'
import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'

import illustrationCloud from '@/files/cloud-platform-illustration.png'
import { pricingTableUrl } from '@/apps/userconsole/urls'
import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'

import type { FunctionComponent } from 'react'

const features = [
  {
    label: (
      <FormattedMessage
        id='trial-overview.features.create'
        defaultMessage='Create multiple hosted or serverless Elastic instances'
      />
    ),
    iconType: 'checkInCircleFilled',
    iconProps: { color: 'success' },
  },
  {
    label: (
      <FormattedMessage
        id='trial-overview.features.storage-performance'
        defaultMessage='Get unrestricted storage and performance'
      />
    ),
    iconType: 'checkInCircleFilled',
    iconProps: { color: 'success' },
  },
  {
    label: (
      <FormattedMessage
        id='trial-overview.features.access'
        defaultMessage='Access more support options for your organization'
      />
    ),
    iconType: 'checkInCircleFilled',
    iconProps: { color: 'success' },
  },
  {
    label: (
      <FormattedMessage
        id='trial-overview.features.unlock'
        defaultMessage='Unlock advanced features such as cross-cluster operations'
      />
    ),
    iconType: 'checkInCircleFilled',
    iconProps: { color: 'success' },
  },
]

const FeaturesOverview: FunctionComponent = () => {
  const { data } = useBillingDetails()
  const isGovCloud = Boolean(useConfig(Feature.hideIrrelevantSectionsFromGovCloud))
  const isMPCustomer = data && isMarketplaceCustomer(data)
  const isCCFlowAvailable = !isGovCloud && !isMPCustomer

  const getTitle = () => {
    if (isMPCustomer) {
      return (
        <FormattedMessage
          id='trial-overview.features.title.marketplace'
          defaultMessage='What do I get from a Marketplace Cloud Subscription?'
        />
      )
    }

    return (
      <FormattedMessage
        id='trial-overview.features.title'
        defaultMessage='What do I get from an Elastic Cloud Subscription?'
      />
    )
  }

  return (
    <EuiPanel paddingSize='l' hasBorder={true} data-test-id='trial-features-overview'>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={8}>
          <EuiTitle size='s'>
            <h4>{getTitle()}</h4>
          </EuiTitle>

          <EuiSpacer />

          <EuiListGroup
            listItems={features}
            maxWidth={false}
            flush={true}
            wrapText={true}
            color='subdued'
          />
        </EuiFlexItem>
        <EuiHideFor sizes={['xs', 's', 'm', 'l']}>
          <EuiFlexItem grow={4}>
            <EuiImage size='fullWidth' src={illustrationCloud} alt='Illustration cloud' />
          </EuiFlexItem>
        </EuiHideFor>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize='m'>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            data-test-id='trial-overview.features.pricing-button'
            color='primary'
            href={pricingTableUrl()}
            target='_blank'
          >
            <FormattedMessage
              id='trial-overview.features.pricing-button'
              defaultMessage='View detailed pricing'
            />
          </EuiButtonEmpty>
        </EuiFlexItem>
        {isCCFlowAvailable && (
          <EuiFlexItem grow={false}>
            <CreditCardModalButton type='full' fill={true}>
              <FormattedMessage id='trial-overview.features.subscribe' defaultMessage='Subscribe' />
            </CreditCardModalButton>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default FeaturesOverview
