/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React from 'react'

import { EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import waves from '@/files/waves-lightblue.svg'

interface Props {
  title: React.ReactNode
  content: React.ReactNode
  footer?: React.ReactNode
}

const SubscriptionOverviewPanel = ({ title, content, footer }: Props) => (
  <EuiPanel
    css={css({
      backgroundImage: `url("${waves}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      backgroundSize: 'contain',
      paddingRight: 70,
    })}
    paddingSize='l'
    hasShadow={false}
    hasBorder={true}
    data-test-id='trial-subscription-overview'
  >
    <EuiTitle size='m' data-test-id='trial-subscription-overview-title'>
      <h3>{title}</h3>
    </EuiTitle>

    <EuiSpacer size='m' />

    <EuiText size='s'>{content}</EuiText>

    <EuiSpacer size='l' />

    {footer}
  </EuiPanel>
)

export default SubscriptionOverviewPanel
