/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { SaasOrganizationResponse } from '@modules/cloud-api/v1/types'
import type {
  OrganizationFromApi,
  ProfileState,
  SaasUserDomain,
  UserProfile,
  UserProfileData,
  UserProfileFromApi,
} from '@modules/ui-types'

import { getTrialContext } from './trial'

interface GetUsernameParams {
  isHeroku: boolean
  profile: ProfileState
}

export function getUsername({ isHeroku, profile }: GetUsernameParams): string | null {
  const herokuEmail = profile?.data.heroku?.owner_email

  if (isHeroku && herokuEmail) {
    return herokuEmail
  }

  const username = profile && (profile.email || profile.user_id)

  if (!username) {
    return null
  }

  return String(username)
}

export function saasOrganizationToUserProfile({
  organization,
  subscription,
  rules,
  trials,
}: SaasOrganizationResponse): UserProfile {
  const profileFromApi: OrganizationFromApi = {
    ...organization,
    ...subscription,
    ...rules,
    // SaasOrganization doesn't have the user_id and email attributes.
    // Filling them with empty values since they are not being used by any component.
    // This won't be needed once the saasUserToUserProfile function is deleted and
    // saasOrganization is passed directly to the components that used to require a profile.
    user_id: 0,
    email: '',
    mfa_enforced: true,
    trials,
    domain: organization.domain as SaasUserDomain,
    data: organization.data as UserProfileData,
  }

  return enhanceUserProfileFromApi(profileFromApi, null) as UserProfile
}

export function enhanceUserProfileFromApi(
  userOrOrganization: UserProfileFromApi | OrganizationFromApi | undefined,
  userProfileState?: ProfileState,
): ProfileState {
  if (!userOrOrganization) {
    return null
  }

  const { driftJwt } = userProfileState || {}
  const { level, role_assignments } = userOrOrganization
  const trialContext = getTrialContext(userOrOrganization)
  const isPremium = level !== `standard` && !trialContext.inTrial

  return {
    ...userOrOrganization,
    ...trialContext,
    driftJwt,
    isPremium,
    level,
    canUploadPlugins:
      (userOrOrganization.allow_plugins || userOrOrganization.allow_bundles) === true,
    allow_plugins: userOrOrganization.allow_plugins,
    allow_bundles: userOrOrganization.allow_bundles,
    role_assignments,
  }
}

export function getUserUsecase(profile: UserProfileFromApi): string {
  // See https://github.com/microsoft/TypeScript/issues/41585
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return profile?.data?.discovery?.use_case?.toLowerCase?.() || ''
}
