/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

// eslint-disable-next-line import/no-restricted-paths
import { gte } from '@/lib/semver'
// eslint-disable-next-line import/no-restricted-paths
import { getVersion } from '@/lib/stackDeployments/selectors/fundamentals'

import type { DeploymentGetResponse } from '../../cloud-api/v1/types'

export const isAttributesValidForRoute = (
  deployment?: DeploymentGetResponse,
): { security: boolean; token: boolean } => {
  function isAttributeValid(version) {
    return deployment
      ? gte(
          getVersion({ deployment: deployment as unknown as DeploymentGetResponse }) || '0.0.0',
          version,
        )
      : false
  }

  return {
    security: isAttributeValid(`8.19.0`),
    token: isAttributeValid(`8.18.0`),
  }
}
