/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import EcuHelpTooltip from '@/components/EcuHelp/EcuHelpTooltip'

import type { ReactElement, ReactNode } from 'react'
import type { FilterState } from '..'

interface Props {
  children?: ReactNode
  onFilterChange?: (id: string, value: any) => void
  filter?: FilterState
  hasBorder?: boolean
  title?: ReactElement
}

class PrepaidBalancePanel extends Component<Props> {
  static defaultProps = {
    title: (
      <h6>
        <FormattedMessage
          id='billing-details-summary.prepaid-account-credits'
          defaultMessage='AVAILABLE CREDITS'
        />
      </h6>
    ),
  }

  render(): ReactElement {
    const { children, hasBorder } = this.props

    return (
      <EuiPanel
        grow={false}
        className='billing-panel'
        hasBorder={hasBorder}
        data-test-id='available-credits'
        paddingSize='l'
      >
        <EuiFlexGroup alignItems='center'>
          <EuiFlexItem>{this.renderPanelTitle()}</EuiFlexItem>
          <EuiFlexItem grow={false}>{this.renderFilterButtons()}</EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        {children}
      </EuiPanel>
    )
  }

  renderPanelTitle(): ReactElement {
    const { title } = this.props

    return (
      <EuiTitle size='xxxs'>
        <EuiFlexGroup gutterSize='none' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>{title}</EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EcuHelpTooltip />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiTitle>
    )
  }

  renderFilterButtons(): ReactNode {
    const { filter, onFilterChange } = this.props

    if (!filter || !onFilterChange) {
      return null
    }

    const filterButtons = [
      {
        id: 'all',
        'data-test-subj': 'filter-button-all',
        label: <FormattedMessage id='billing-details-summary.all-label' defaultMessage='All' />,
      },
      {
        id: 'active',
        'data-test-subj': 'filter-button-active',
        label: (
          <FormattedMessage id='billing-details-summary.active-label' defaultMessage='Active' />
        ),
      },
      {
        id: 'expired',
        'data-test-subj': 'filter-button-expired',
        label: (
          <FormattedMessage
            id='billing-details-summary.deactivated-label'
            defaultMessage='Deactivated'
          />
        ),
      },
      {
        id: 'future',
        'data-test-subj': 'filter-button-future',
        label: (
          <FormattedMessage id='billing-details-summary.future-label' defaultMessage='Future' />
        ),
      },
    ]

    return (
      <EuiButtonGroup
        legend='Filter line items'
        options={filterButtons}
        idSelected={filter}
        onChange={onFilterChange}
        type='single'
      />
    )
  }
}

export default PrepaidBalancePanel
