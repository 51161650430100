/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import cx from 'classnames'
import React, { PureComponent, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiHorizontalRule,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiIconTip,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiDescribedFormGroup,
} from '@elastic/eui'

import type { SaasUserProfile, SaasUserRules } from '@modules/cloud-api/v1/types'
import type { AccountUI, AsyncRequestState, UserProfile } from '@modules/ui-types'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import MfaManagement from '@modules/mfa-management/MfaManagement'
import type { AuthContextData } from '@modules/auth/context'

import { portalUserSettingsCrumbs } from '@/lib/portalCrumbBuilder'
import RolesSummary from '@/components/Organization/OrganizationMembers/OrganizationMemberRoleAssignments/RolesSummary'

import PortalPage from '../PortalPage'
import { messages as portalMessages } from '../../messages'

import UserProfileForm from './UserProfileForm'
import ChangePasswordForm from './ChangePasswordForm'

import './userAccountSettings.scss'

interface Props {
  accountDetails: UserProfile | undefined
  ui: AccountUI
  fetchAccountDetailsIfNeeded: () => Promise<any>
  fetchAccountDetailsRequest: AsyncRequestState
  resetUpdateAccountDetailsRequest: () => void
  resetUpdateAccountPasswordRequest: () => void
  updateAccountDetailsRequest: AsyncRequestState
  updateAccountDetails: (accountDetails: SaasUserProfile & SaasUserRules) => any
  updateAccountPassword: (args: { oldPassword: string; newPassword: string; email: string }) => void
  updateAccountPasswordRequest: AsyncRequestState
  authContext: AuthContextData
}

class UserAccountSettings extends PureComponent<Props> {
  componentDidMount() {
    const { fetchAccountDetailsIfNeeded } = this.props

    fetchAccountDetailsIfNeeded()
  }

  componentWillUnmount() {
    this.props.resetUpdateAccountDetailsRequest()
    this.props.resetUpdateAccountPasswordRequest()
  }

  render() {
    const isLoading = this.isLoading()

    return (
      <PortalPage
        breadcrumbs={portalUserSettingsCrumbs()}
        className={cx('cloud-portal-user-account-settings', {
          'cloud-portal-page-loading': isLoading,
        })}
        contentHeader={this.renderHeader()}
      >
        {this.renderContent()}
      </PortalPage>
    )
  }

  renderHeader() {
    return (
      <EuiFlexGroup direction='column' gutterSize='s'>
        <EuiFlexItem>
          <EuiTitle size='l'>
            <h2>
              <FormattedMessage {...portalMessages.userSettings} />
            </h2>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup gutterSize='s' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiText size='s' color='subdued' data-test-id='user-settings-profile.user-id'>
                <FormattedMessage
                  id='user-settings-profile.user-id'
                  defaultMessage='User ID: {userID}'
                  values={{ userID: this.props.accountDetails?.user_id }}
                  data-test-id={'user-settings-profile.user-id'}
                />
              </EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiIconTip
                position='left'
                size='m'
                type='questionInCircle'
                color='subdued'
                content={
                  <FormattedMessage
                    id='user-settings-profile.user-id-tooltip'
                    defaultMessage='Your User ID is sometimes used instead of your email address to identify you across Elastic Cloud, for example in deployment audit logs. The User ID is set automatically.'
                  />
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderContent() {
    const {
      accountDetails,
      updateAccountDetailsRequest,
      updateAccountPassword,
      updateAccountPasswordRequest,
      resetUpdateAccountPasswordRequest,
      ui,
    } = this.props

    const isLoading = this.isLoading()

    if (!accountDetails || isLoading) {
      return <EuiSkeletonText />
    }

    return (
      <PrivacySensitiveContainer>
        <EuiSpacer size='m' />

        <div className='profile-wrapper'>
          <UserProfileForm
            ui={ui}
            updateAccountDetailsRequest={updateAccountDetailsRequest}
            accountDetails={accountDetails}
            updateAccountDetails={this.updateAccountDetails}
            resetUpdateAccountDetailsRequest={this.props.resetUpdateAccountDetailsRequest}
          />

          <EuiSpacer size='m' />

          <EuiHorizontalRule margin='xs' />

          <EuiSpacer size='m' />

          <ChangePasswordForm
            accountDetails={accountDetails}
            updateAccountPassword={updateAccountPassword}
            updateAccountPasswordRequest={updateAccountPasswordRequest}
            resetUpdateAccountPasswordRequest={resetUpdateAccountPasswordRequest}
          />

          {
            // MFA can be only managed if the authentication method is username and password
            this.props.authContext.method === 'username-and-password' && (
              <Fragment>
                <EuiSpacer />

                <EuiHorizontalRule margin='xs' />

                <EuiSpacer size='m' />

                <MfaManagement />
              </Fragment>
            )
          }

          <EuiSpacer size='m' />

          {accountDetails.organization_id && accountDetails.role_assignments && (
            <Fragment>
              <EuiHorizontalRule margin='xs' />
              <EuiSpacer size='m' />

              <EuiDescribedFormGroup
                ratio='third'
                title={
                  <h3>
                    <FormattedMessage
                      id='user-settings.current-user.roles'
                      defaultMessage='Roles'
                    />
                  </h3>
                }
              >
                <EuiFlexGroup
                  direction='column'
                  gutterSize='none'
                  justifyContent='flexStart'
                  alignItems='baseline'
                >
                  <EuiFlexItem>
                    <RolesSummary
                      memberOrApiKey='member'
                      organizationId={accountDetails.organization_id}
                      roleAssignments={accountDetails.role_assignments}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiDescribedFormGroup>
            </Fragment>
          )}
        </div>
      </PrivacySensitiveContainer>
    )
  }

  updateAccountDetails = (fieldValues) =>
    this.props.updateAccountDetails({
      ...fieldValues,
    })

  isLoading = (): boolean => {
    const { accountDetails, fetchAccountDetailsRequest } = this.props

    const isLoading = !accountDetails || fetchAccountDetailsRequest.inProgress

    return isLoading
  }
}

export default UserAccountSettings
