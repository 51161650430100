/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Component } from 'react'
import classNames from 'classnames'

import SearchInputWrapper from './SearchInputWrapper'

type Props = {
  onBeforeExpand?: () => void
}

type State = {
  isSearchExpanded: boolean
}

class SearchAnythingBox extends Component<Props, State> {
  state: State = {
    isSearchExpanded: false,
  }

  render() {
    const { isSearchExpanded } = this.state

    const searchStyle = `
    {
      display: flex;
      align-items: center;
      height: 48px;
      margin: 0 12px;
      width: 100%;

      .euiFormControlLayout {
        transition: width 0.2s ease-in-out;
        width: 40px;
      }

      .euiFieldText--withIcon {
        padding-left: 28px;
      }

      .euiIcon {
        pointer-events: none;
      }

      .euiFieldText {
        width: 100%;
      }
    `

    const searchStyleCollapsed = `
       {
        text-align: center;

        // we need both because: svg icon is hard to style, but input needs override as well
        .euiFormControlLayout,
        .euiFieldText {
          cursor: pointer;
        }

        /* center the search icon while we have a minimized input */
        [class*='euiFormControlLayout-compressed'] .euiFormControlLayoutIcons {
          left: 12px;
        }
      }
    `

    const searchStyleExpanded = `
       {
        .euiFormControlLayout {
          width: 24rem;
        }

        .euiFieldText--withIcon {
          padding-left: 40px;
        }
      }
    `

    const searchStyleGlobal = isSearchExpanded
      ? `${searchStyle} ${searchStyleExpanded}`
      : `${searchStyle} ${searchStyleCollapsed}`

    const searchClasses = classNames(`chromeHeader-search`, {
      'chromeHeader-searchCollapsed': !isSearchExpanded,
      'chromeHeader-searchExpanded': isSearchExpanded,
    })

    return (
      <div
        css={css`
          ${searchStyleGlobal}
        `}
        className={searchClasses}
        onFocus={() => this.expandSearch()}
        onClick={() => this.expandSearch()}
      >
        <SearchInputWrapper
          isSearchExpanded={isSearchExpanded}
          onHide={() => this.collapseSearch()}
        />
      </div>
    )
  }

  expandSearch() {
    const { onBeforeExpand } = this.props
    const { isSearchExpanded } = this.state

    if (isSearchExpanded) {
      return
    }

    if (onBeforeExpand) {
      onBeforeExpand()
    }

    this.setState({ isSearchExpanded: true })
  }

  collapseSearch() {
    const { isSearchExpanded } = this.state

    if (!isSearchExpanded) {
      return
    }

    this.setState({ isSearchExpanded: false })
  }
}

export default SearchAnythingBox
