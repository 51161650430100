/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { replaceIn } from '@/lib/immutability-helpers'

import type { Action } from '@/types/redux'
import type { DriftChatState } from './types'

export const SHOW_DRIFT_CHAT = `SHOW_DRIFT_CHAT`

export const HIDE_DRIFT_CHAT = `HIDE_DRIFT_CHAT`

export const TOGGLE_DRIFT_CHAT = `TOGGLE_DRIFT_CHAT`

export type DriftChatActions =
  | typeof SHOW_DRIFT_CHAT
  | typeof HIDE_DRIFT_CHAT
  | typeof TOGGLE_DRIFT_CHAT

export default function driftChatReducer(
  state: DriftChatState = {
    isChatOpen: false,
  },
  action: Action<any>,
): DriftChatState {
  const actionType = action.type as DriftChatActions

  switch (actionType) {
    case 'SHOW_DRIFT_CHAT':
      if (state.isChatOpen) {
        return state
      }

      return replaceIn(state, [`isChatOpen`], true)
    case 'HIDE_DRIFT_CHAT':
      if (!state.isChatOpen) {
        return state
      }

      return replaceIn(state, [`isChatOpen`], false)
    case 'TOGGLE_DRIFT_CHAT':
      return replaceIn(state, [`isChatOpen`], !state.isChatOpen)
    default:
      return state
  }
}
