/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/* global FS */
import React, { Fragment, useEffect, useState } from 'react'
import { sha256 } from 'js-sha256'

import Feature from '@modules/utils/feature'
import { useEnhancedUserProfile } from '@modules/profile-lib/hooks'
import type { MiddlewareComponent } from '@modules/app/types'
import ProfileProvider from '@modules/profile-lib/ProfileProvider'
import { useConfig } from '@modules/cui/ConfigContext'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import BillingDetailsMiddleware from '@modules/billing-lib/billingDetails/BillingDetailsMiddleware'
import { getBillingThirdPartyIntegrationContext } from '@modules/billing-lib/billingDetails/utils'

import { isElasticStaff as isElasticEmail } from '@/lib/validateEmail'

const FullStoryMiddleware: MiddlewareComponent = ({ children }) => {
  const isFullStoryActivated = useConfig(Feature.userconsoleRunFullStory)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const profile = useEnhancedUserProfile()
  const billingDetails = useBillingDetails()

  useEffect(() => {
    if (!profile || !profile.user_id || !billingDetails.data) {
      return
    }

    const isElasticStaff = isElasticEmail(profile.email)
    const id = profile.user_id.toString()
    const hashedId = sha256(id)

    if (isFullStoryActivated && !isInitialized) {
      FS.identify(hashedId)
      FS.setUserVars({
        trial: profile.inTrial,
        hasExpiredTrial: profile.hasExpiredTrial,
        level: profile.level,
        ...(profile.organization_id ? { organization: profile.organization_id } : {}),
        isElasticStaff,
        rawUserId: id,
        // Billing details context
        ...getBillingThirdPartyIntegrationContext(billingDetails.data),
      })

      setIsInitialized(true)
    }
  }, [isFullStoryActivated, isInitialized, profile, billingDetails.data])

  return <Fragment>{children}</Fragment>
}

FullStoryMiddleware.middleware = {
  displayName: 'FullStoryMiddleware',
  dependencies: [ProfileProvider, BillingDetailsMiddleware],
}

export default FullStoryMiddleware
